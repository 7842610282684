import BigNumber from 'bignumber.js';
import { computed, ComputedRef, unref } from 'vue';
import { BIG_ZERO, max } from '@/utils/bigNumber';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { TRANSACTION_FEE_NATIVE } from '@/constants/TRANSACTION_FEE_NATIVE';
import { Token } from '@/sdk/entities/token';
import { isCardanoMainnetOrTestnet } from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import { fromWei, toWei } from '@/sdk/utils';
import { calculateMinADAAmountIntoCardanoWhenOnlyBridgeFromCardano } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { useCardanoTokens } from '@/store/modules/tokens/useCardanoTokens';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';

export function useTokenBalance(
  token: ComputedRef<Token | undefined> | Token | undefined,
  options: {
    inWei?: boolean;
    subTxFeeFromNativeBalance?: boolean;
    countHowManyCanBridgeFromCardano?: number;
  } = {},
): ComputedRef<string> {
  const { tokenBalanceWei } = useCardanoTokens();
  const { isPresentTokenIntoNetwork } = useTokens();
  const { balances } = useBalances();

  return computed(() => {
    const uToken = unref(token);

    if (!uToken) {
      return '0';
    }

    let weiValue: string;

    if (isCardanoToken(uToken)) {
      weiValue = tokenBalanceWei(uToken.address).value.toFixed(0);
    } else {
      weiValue = balances?.[uToken.unwrapWETH().symbol!]?.balance.raw.toString() || '0';
    }

    if (options.subTxFeeFromNativeBalance && uToken.unwrapWETH().isETHToken()) {
      let fee = TRANSACTION_FEE_NATIVE;
      if (
        ENABLE_FAKE_CARDANO_NETWORK &&
        isPresentTokenIntoNetwork(uToken.unwrapWETH().symbol!, +DEFAULT_CARDANO_CHAIN_ID)
      ) {
        // NOTE:
        // When bridge ADA from Cardano to Milkomeda
        fee = calculateMinADAAmountIntoCardanoWhenOnlyBridgeFromCardano(
          options.countHowManyCanBridgeFromCardano ?? 1,
        );
      }
      const feeInWei = toWei(fee, uToken.unwrapWETH().decimals);

      weiValue = max(BigNumber(weiValue).minus(feeInWei), 0).toFixed(); // TODO handle cardano fee
    }

    if (options.inWei) {
      return new BigNumber(weiValue).toFixed(0);
    } else {
      return fromWei(weiValue, uToken.decimals).toFixed();
    }
  });
}

export function getTokenBalance(
  token?: Token,
  options: {
    inWei?: boolean;
    subTxFeeFromNativeBalance?: boolean;
    countHowManyCanBridgeFromCardano?: number;
  } = {},
): BigNumber {
  const { isPresentTokenIntoNetwork } = useTokens();
  const { balances } = useBalances();

  if (!token) {
    return BIG_ZERO;
  }
  let weiValue = balances?.[token.unwrapWETH().symbol!]?.balance.raw.toString() || '0';

  if (options.subTxFeeFromNativeBalance && token.unwrapWETH().isETHToken()) {
    let fee = TRANSACTION_FEE_NATIVE;
    if (
      ENABLE_FAKE_CARDANO_NETWORK &&
      isPresentTokenIntoNetwork(token.unwrapWETH().symbol!, +DEFAULT_CARDANO_CHAIN_ID)
    ) {
      // NOTE:
      // When bridge ADA from Cardano to Milkomeda
      fee = calculateMinADAAmountIntoCardanoWhenOnlyBridgeFromCardano(
        options?.countHowManyCanBridgeFromCardano ?? 1,
      );
    }
    const feeInWei = toWei(fee, token.unwrapWETH().decimals);

    weiValue = max(BigNumber(weiValue).minus(feeInWei), 0).toFixed(); // TODO handle cardano fee
  }

  if (options.inWei) {
    return new BigNumber(weiValue);
  } else {
    return fromWei(weiValue, token.decimals);
  }
}

function isCardanoToken(token: Token): boolean {
  return isCardanoMainnetOrTestnet(token.chainId);
}
