import { ethers } from 'ethers';
import { useStore } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { FARMING_ACTION_TYPES } from '@/store/modules/farming/farming.module';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { ROUTES_ACTION_TYPES } from '@/store/modules/routes/routes.module';
import { getFarmingContract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';

export function useFarmingTransactions() {
  const store = useStore();

  async function stake(
    farm: PortfolioFarm,
    amountInWei: string,
  ): Promise<ethers.providers.TransactionReceipt> {
    const farmingContract = getFarmingContract(farm.farm, getInstance().web3.getSigner());

    const callArgs = {
      _amount: amountInWei,
    };

    console.groupCollapsed('[FARMING:STAKE] deposit : ', farmingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      farmingContract,
      'deposit',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updateFarms();

    return tx;
  }

  async function unstake(
    farm: PortfolioFarm,
    amountInWei: string,
  ): Promise<ethers.providers.TransactionReceipt> {
    const farmingContract = getFarmingContract(farm.farm, getInstance().web3.getSigner());

    const callArgs = {
      _amount: amountInWei,
    };

    console.groupCollapsed('[FARMING:UNSTAKE] withdraw : ', farmingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      farmingContract,
      'withdraw',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updateFarms();

    return tx;
  }

  async function harvest(farm: PortfolioFarm): Promise<ethers.providers.TransactionReceipt> {
    const farmingContract = getFarmingContract(farm.farm, getInstance().web3.getSigner());

    const callArgs = {};

    console.groupCollapsed('[FARMING:HARVEST] getReward : ', farmingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      farmingContract,
      'getReward',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updateFarms();

    return tx;
  }

  async function harvestWithUnwrap(
    farm: PortfolioFarm,
  ): Promise<ethers.providers.TransactionReceipt> {
    const farmingContract = getFarmingContract(farm.farm, getInstance().web3.getSigner());

    const callArgs = {};

    console.groupCollapsed('[FARMING:HARVEST] getRewardWithUnwrap : ', farmingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      farmingContract,
      'getRewardWithUnwrap',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updateFarms();

    return tx;
  }

  async function updateFarms() {
    await store.dispatch(MODULE_NAMES.ROUTES + '/' + ROUTES_ACTION_TYPES.GET_PAIRS_WITH_BALANCES);
    await store.dispatch(MODULE_NAMES.FARMING + '/' + FARMING_ACTION_TYPES.GET_STATUS_FARMS);
    await store.dispatch(MODULE_NAMES.FARMING + '/' + FARMING_ACTION_TYPES.GET_FARMING_LP);
    await store.dispatch(
      MODULE_NAMES.FARMING + '/' + FARMING_ACTION_TYPES.GET_PORTFOLIO_FARMING_LP,
    );
  }

  return {
    stake,
    unstake,
    harvest,
    harvestWithUnwrap,
    updateFarms,
  };
}
