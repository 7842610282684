import { UPDATE_INTERVAL } from '@/helpers/constants';
import { compareTokenAddresses, fromWei } from '@/sdk/utils';
import { CardanoToken } from '@/store/modules/tokens/models/cardano-token';
import { useCardanoWallet } from '@/store/modules/wallet/useCardanoWallet';
import { BIG_ZERO } from '@/utils/bigNumber';
import BigNumber from 'bignumber.js';
import { defineStore } from 'pinia';
import { computed, ComputedRef, ref, watch } from 'vue';
import { loadCardanoTokens } from './loadCardanoTokens';

export const useCardanoTokens = defineStore('cardanoTokens', () => {
  const cardanoWallet = useCardanoWallet();

  const tokens = ref<CardanoToken[]>(loadCardanoTokens());
  const balances = ref<Record<string, BigNumber> | null>();

  const updateBalances = async (): Promise<void> => {
    const wallet = cardanoWallet.wallet;

    if (!wallet || !cardanoWallet.isInjected) {
      return;
    }

    const balancesResponse = await Promise.all(
      tokens.value.map(token => cardanoWallet.getBalance(token.address)),
    );

    balances.value = Object.fromEntries(
      tokens.value.map((token, index) => [token.address, balancesResponse[index]]),
    );
  };

  const getTokenByAddress = (address: string): CardanoToken => {
    const token = tokens.value.find(t => compareTokenAddresses(t.address, address));

    if (!token) {
      throw new Error(`Cardano token ${address} was not found.`);
    }

    return token;
  };

  const tokenBalanceWei = (address: string): ComputedRef<BigNumber> => {
    return computed(() => {
      const balance = balances.value?.[address];

      if (!balance) {
        return BIG_ZERO;
      }

      return balance;
    });
  };

  const tokenBalanceRelative = (address: string): ComputedRef<BigNumber> => {
    return computed(() => {
      const balanceWei = tokenBalanceWei(address);
      const token = getTokenByAddress(address);

      return fromWei(balanceWei.value, token.decimals);
    });
  };

  watch(
    () => cardanoWallet.address,
    val => {
      if (val) {
        updateBalances();
      }
    },
  );

  setInterval(updateBalances, UPDATE_INTERVAL);

  return {
    tokens,
    balances,
    updateBalances,
    getTokenByAddress,
    tokenBalanceWei,
    tokenBalanceRelative,
  };
});
