<template>
  <div class="inline-flex align-items-center">
    <div>
      <span class="mr-s1" v-if="tokenAAmount">{{ tokenAAmount }}</span>
      <span>{{ tokenASymbol }}</span>
    </div>
    <div class="mx-s1">&#8729;</div>
    <div>
      <span class="mr-s1" v-if="tokenBAmount">{{ tokenBAmount }}</span>
      <span>{{ tokenBSymbol }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TokenPairText',
  props: {
    tokenAAmount: [Number, String],
    tokenASymbol: String,
    tokenBAmount: [Number, String],
    tokenBSymbol: String,
  },
};
</script>

<style scoped></style>
