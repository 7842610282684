import BigNumber from 'bignumber.js';

const roundMixin = {
  methods: {
    mathMixRound(value, decimalPlaces = 6, roundingMode?) {
      const rounding = roundingMode ?? BigNumber.ROUND_DOWN;
      const bnValue = value instanceof BigNumber ? value : new BigNumber(value);
      if (bnValue.eq(0)) {
        return '0';
      }
      return bnValue.decimalPlaces(decimalPlaces, rounding).toString();
    },
  },
};

export default roundMixin;
