import { reactive, watch } from 'vue';
import { defineStore } from 'pinia';
import { fromWei } from '@/sdk/utils';
import { Staking } from '@/sdk/entities/staking';
import { Token } from '@/sdk/entities/token';
import { StakingPool } from '@/store/modules/staking/models/staking-pool';
import { IStakingStakeForm } from '@/store/modules/staking/models/staking-stake-form.interface';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { useStakingMilkomedaWSCBridge } from '@/store/modules/staking/useStakingMilkomedaWSCBridge';
import { useAutoStakingStake } from '@/store/modules/staking/auto/stake/useAutoStakingStake';
import { useManualStakingStake } from '@/store/modules/staking/manual/stake/useManualStakingStake';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';

type IStakingStake = {
  stakingPool: StakingPool | null;
  stakingStakeForm: IStakingStakeForm | null;
};

export const useStakingStake = defineStore('stakingStake', () => {
  const {
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useStakingMilkomedaWSCBridge();
  const {
    stakingStakeForm: autoStakingStakeForm,
    setStakeToPool: autoSetStakeToPool,
    checkAllowance: autoCheckAllowance,
    setAllowance: autoSetAllowance,
    doStake: autoDoStake,
    $reset: autoReset,
  } = useAutoStakingStake();
  const {
    stakingStakeForm: manualStakingStakeForm,
    setStakeToPool: manualSetStakeToPool,
    checkAllowance: manualCheckAllowance,
    setAllowance: manualSetAllowance,
    doStake: manualDoStake,
    $reset: manualReset,
  } = useManualStakingStake();

  const stakingStake = reactive<IStakingStake>({
    stakingPool: null,
    stakingStakeForm: null,
  });

  const setStakeToPoolByStakingPool: Record<
    StakingPool,
    (pool: Staking, token: Token, amountInWei: string) => void
  > = {
    auto: (pool: Staking, token: Token, amountInWei: string) =>
      autoSetStakeToPool(pool, token, amountInWei),
    manual: (pool: Staking, token: Token, amountInWei: string) =>
      manualSetStakeToPool(pool, token, amountInWei),
  };

  const checkAllowanceByStakingPool: Record<StakingPool, () => void> = {
    auto: () => autoCheckAllowance(),
    manual: () => manualCheckAllowance(),
  };

  const setAllowanceByStakingPool: Record<StakingPool, () => void> = {
    auto: () => autoSetAllowance(),
    manual: () => manualSetAllowance(),
  };

  const doStakeByStakingPool: Record<StakingPool, () => void> = {
    auto: () => autoDoStake(),
    manual: () => manualDoStake(),
  };

  function $reset(): void {
    autoReset();
    manualReset();
    stakingStake.stakingPool = null;
    resetMilkomedaWSCBridge();
  }

  function checkStakeForBridgeFromCardano(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;

    resetMilkomedaWSCBridge();
    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(false);
    const bridgeTokens: BridgeToken[] = [
      {
        amount: fromWei(amountInWei, token.decimals).toString(),
        token,
      },
    ];

    setBridgeTokensFromCardano(bridgeTokens);
  }

  function setStakeToPool(
    stakingPool: StakingPool,
    pool: Staking,
    token: Token,
    amountInWei: string,
  ): void {
    stakingStake.stakingPool = stakingPool;
    setStakeToPoolByStakingPool[stakingStake.stakingPool](pool, token, amountInWei);
  }

  async function checkAllowance() {
    if (!stakingStake.stakingPool) return;

    await checkAllowanceByStakingPool[stakingStake.stakingPool]();
  }

  async function setAllowance() {
    if (!stakingStake.stakingPool) return;

    await setAllowanceByStakingPool[stakingStake.stakingPool]();
  }

  async function doStake() {
    if (!stakingStake.stakingPool) return;

    await doStakeByStakingPool[stakingStake.stakingPool]();
  }

  watch(
    () => stakingStake.stakingPool,
    stakingPool => {
      if (!stakingPool) {
        stakingStake.stakingStakeForm = null;
        return;
      }

      if (stakingPool === 'auto') {
        stakingStake.stakingStakeForm = autoStakingStakeForm;
      } else {
        stakingStake.stakingStakeForm = manualStakingStakeForm;
      }
    },
  );

  return {
    stakingStake,
    checkStakeForBridgeFromCardano,
    setStakeToPool,
    checkAllowance,
    setAllowance,
    doStake,
    $reset,
  };
});
