import { ethers } from 'ethers';
import {
  Asset,
  BridgeName,
  BridgeProvider,
  BridgeResponse,
  CardanoWallet,
  ChainName,
} from 'crypto-sdk';
import { Token } from '@/sdk/entities/token';
import { safeParseUnits } from '@/helpers/utils';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';

export type CardanoBridgeAsset =
  | {
      gas: string;
      token?: Asset;
    }
  | {
      gas?: string;
      token: Asset;
    };

export type MilkomedaBridgeAsset =
  | {
      gas: string;
      token?: Asset;
    }
  | {
      gas?: string;
      token: Asset;
    };

// Bridge from Cardano to Milkomeda
export async function bridgeFromCardanoToMilkomeda(
  gas:
    | {
        amount: string;
        token: Token;
      }
    | undefined,
  bridge:
    | {
        amount: string;
        token: Token;
      }
    | undefined,
  cardanoWallet: CardanoWallet,
  milkomedaWalletAddress: string,
  opts?: {
    isDemo: boolean;
  },
): Promise<BridgeResponse> {
  const cardanoAsset = buildCardanoAsset(gas, bridge);

  const to = {
    chain: SUPPORTED_NETWORK_MODE === 'MAINNET' ? ChainName.Milkomeda : ChainName.MilkomedaDevnet,
    address: milkomedaWalletAddress,
  };

  const by = BridgeName.Milkomeda;

  const options = {
    isDemo: opts ? opts.isDemo : false,
    ttl: 3600,
  };

  console.groupCollapsed('[BRIDGE_FROM_CARDANO] Call `bridge` : ');
  console.log('params: ', {
    cardanoAsset,
    to,
    by,
    options,
  });
  console.groupEnd();

  return cardanoWallet.bridgeExtra(cardanoAsset, to, by, options);
}

// Bridge from Milkomeda to Cardano
export async function bridgeFromMilkomedaToCardano(
  gas: {
    amount: string;
    token: Token;
  },
  bridge: {
    amount: string;
    token: Token;
  } | null,
  milkomedaBridgeProvider: BridgeProvider,
  cardanoWalletAddress: string,
  milkomedaWalletAddress: string,
): Promise<BridgeResponse> {
  const milkomedaAsset = buildMilkomedaAsset(gas, bridge);

  const from = {
    chain: SUPPORTED_NETWORK_MODE === 'MAINNET' ? ChainName.Milkomeda : ChainName.MilkomedaDevnet,
    address: milkomedaWalletAddress,
  };

  const to = {
    chain: SUPPORTED_NETWORK_MODE === 'MAINNET' ? ChainName.Cardano : ChainName.CardanoTestnet,
    address: cardanoWalletAddress,
  };

  const signer = getInstance().web3.getSigner();

  console.groupCollapsed('[BRIDGE_FROM_MILKOMEDA] Call `bridgeFromEVM` : ');
  console.log('bridgeFromEVM:params: ', {
    milkomedaAsset,
    from,
    to,
    signer,
  });
  console.groupEnd();

  return milkomedaBridgeProvider.bridgeFromEVMExtra(milkomedaAsset, from, to, signer);
}

// Approve for bridge from Milkomeda to Cardano
export async function approveForBridgeFromMilkomedaToCardano(
  bridge: {
    amount: string;
    token: Token;
  },
  milkomedaBridgeProvider: BridgeProvider,
  cardanoWalletAddress: string,
  milkomedaWalletAddress: string,
): Promise<ethers.providers.TransactionResponse | undefined> {
  const milkomedaAsset = buildMilkomedaApproveAsset(bridge);

  const from = {
    chain: SUPPORTED_NETWORK_MODE === 'MAINNET' ? ChainName.Milkomeda : ChainName.MilkomedaDevnet,
    address: milkomedaWalletAddress,
  };

  const to = {
    chain: SUPPORTED_NETWORK_MODE === 'MAINNET' ? ChainName.Cardano : ChainName.CardanoTestnet,
    address: cardanoWalletAddress,
  };

  const signer = getInstance().web3.getSigner();

  console.groupCollapsed('[BRIDGE_FROM_MILKOMEDA] Call `approveForBridge` : ');
  console.log('approveForBridge:params: ', {
    milkomedaAsset,
    from,
    to,
    signer,
  });
  console.groupEnd();

  return milkomedaBridgeProvider.approveForBridge(milkomedaAsset, from, to, signer);
}

function buildCardanoAsset(
  gas:
    | {
        amount: string;
        token: Token;
      }
    | undefined,
  bridge:
    | {
        amount: string;
        token: Token;
      }
    | undefined,
): CardanoBridgeAsset {
  const cardanoAsset: CardanoBridgeAsset = <CardanoBridgeAsset>{};

  if (gas) {
    cardanoAsset.gas = safeParseUnits(gas.amount, gas.token.decimals);
  }

  if (bridge) {
    const inputAmount = safeParseUnits(bridge.amount, bridge.token.decimals);
    cardanoAsset.token = {
      token: bridge.token.address ?? '',
      quantity: inputAmount,
    };
  }

  return cardanoAsset;
}

function buildMilkomedaAsset(
  gas: {
    amount: string;
    token: Token;
  },
  bridge: {
    amount: string;
    token: Token;
  } | null,
): MilkomedaBridgeAsset {
  const milkomedaAssets: MilkomedaBridgeAsset = {
    gas: safeParseUnits(gas.amount, gas.token.decimals),
  };

  if (bridge) {
    milkomedaAssets.token = {
      token: bridge.token.address,
      quantity: safeParseUnits(bridge.amount, bridge.token.decimals),
    };
  }

  return milkomedaAssets;
}

function buildMilkomedaApproveAsset(bridge: { amount: string; token: Token }): Asset {
  return {
    token: bridge.token.address,
    quantity: safeParseUnits(bridge.amount, bridge.token.decimals),
  };
}
