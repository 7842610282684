import { ethers } from 'ethers';
import { useStore } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { getStakingContract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { Staking } from '@/sdk/entities/staking';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { STAKING_ACTION_TYPES } from '@/store/modules/staking/staking.module';

export function useManualStakingTransactions() {
  const store = useStore();
  const { updateTokenBalances } = useBalances();

  async function stake(
    pool: Staking,
    amountInWei: string,
  ): Promise<ethers.providers.TransactionReceipt> {
    const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());

    const callArgs = {
      _amount: amountInWei,
    };

    console.groupCollapsed('[MANUAL:POOL:STAKE] deposit : ', stakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      stakingContract,
      'deposit',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function unstake(
    pool: Staking,
    amountInWei: string,
  ): Promise<ethers.providers.TransactionReceipt> {
    const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());

    const callArgs = {
      _amount: amountInWei,
    };

    console.groupCollapsed('[MANUAL:POOL:UNSTAKE] withdraw : ', stakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      stakingContract,
      'withdraw',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function harvest(pool: Staking): Promise<ethers.providers.TransactionReceipt> {
    const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());

    const callArgs = {};

    console.groupCollapsed('[MANUAL:POOL:HARVEST] getReward : ', stakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      stakingContract,
      'getReward',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function compound(pool: Staking): Promise<ethers.providers.TransactionReceipt> {
    const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());

    const callArgs = {};

    console.groupCollapsed('[MANUAL:POOL:COMPOUND] compound : ', stakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      stakingContract,
      'compound',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function updatePools() {
    await updateTokenBalances();
    await store.dispatch(MODULE_NAMES.STAKING + '/' + STAKING_ACTION_TYPES.GET_STATUS_STAKING);
    await store.dispatch(MODULE_NAMES.STAKING + '/' + STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS);
  }

  return {
    stake,
    unstake,
    harvest,
    compound,
    updatePools,
  };
}
