import { defineStore } from 'pinia';
import { reactive, watch } from 'vue';
import { StepInfo } from '@/components/stepper/stepper.types';
import { StakingAction } from '@/store/modules/staking/models/staking-action';
import { StakingPool } from '@/store/modules/staking/models/staking-pool';
import { useStakingStakeSteps } from '@/store/modules/staking/stake/useStakingStakeSteps';
import { useStakingUnstakeSteps } from '@/store/modules/staking/unstake/useStakingUnstakeSteps';
import { useStakingHarvestSteps } from './harvest/useStakingHarvestSteps';
import { useStakingCompoundSteps } from './compound/useStakingCompoundSteps';
import { useStakingClaimSteps } from './claim/useStakingClaimSteps';

interface IStakingStepsState {
  pool?: StakingPool;
  action?: StakingAction;
  steps: StepInfo[];
  isShown: boolean;
  inProgress: boolean;
}

export const useStakingSteps = defineStore('stakingSteps', () => {
  const stakingStakeStepsStore = useStakingStakeSteps();
  const stakingUnstakeStepsStore = useStakingUnstakeSteps();
  const stakingHarvestStepsStore = useStakingHarvestSteps();
  const stakingCompoundStepsStore = useStakingCompoundSteps();
  const stakingClaimStepsStore = useStakingClaimSteps();

  const stakingStepsByAction: Record<StakingAction, () => StepInfo[]> = {
    stake: () => stakingStakeStepsStore.stakingStakeSteps.steps,
    unstake: () => stakingUnstakeStepsStore.stakingUnstakeSteps.steps,
    harvest: () => stakingHarvestStepsStore.stakingHarvestSteps.steps,
    compound: () => stakingCompoundStepsStore.stakingCompoundSteps.steps,
    claim: () => stakingClaimStepsStore.stakingClaimSteps.steps,
  };

  const isStepsShown: Record<StakingAction, () => boolean> = {
    stake: () => stakingStakeStepsStore.stakingStakeSteps.isShown,
    unstake: () => stakingUnstakeStepsStore.stakingUnstakeSteps.isShown,
    harvest: () => stakingHarvestStepsStore.stakingHarvestSteps.isShown,
    compound: () => stakingCompoundStepsStore.stakingCompoundSteps.isShown,
    claim: () => stakingClaimStepsStore.stakingClaimSteps.isShown,
  };

  const doReset: Record<StakingAction, () => void> = {
    stake: stakingStakeStepsStore.$reset,
    unstake: stakingUnstakeStepsStore.$reset,
    harvest: stakingHarvestStepsStore.$reset,
    compound: stakingCompoundStepsStore.$reset,
    claim: stakingClaimStepsStore.$reset,
  };

  const doSetIsShown: Record<StakingAction, (isShow: boolean) => void> = {
    stake: (isShow: boolean) => (stakingStakeStepsStore.stakingStakeSteps.isShown = isShow),
    unstake: (isShow: boolean) => (stakingUnstakeStepsStore.stakingUnstakeSteps.isShown = isShow),
    harvest: (isShow: boolean) => (stakingHarvestStepsStore.stakingHarvestSteps.isShown = isShow),
    compound: (isShow: boolean) =>
      (stakingCompoundStepsStore.stakingCompoundSteps.isShown = isShow),
    claim: (isShow: boolean) => (stakingClaimStepsStore.stakingClaimSteps.isShown = isShow),
  };

  const doPrepareSteps: Record<StakingAction, () => void> = {
    stake: stakingStakeStepsStore.prepareSteps,
    unstake: stakingUnstakeStepsStore.prepareSteps,
    harvest: stakingHarvestStepsStore.prepareSteps,
    compound: stakingCompoundStepsStore.prepareSteps,
    claim: stakingClaimStepsStore.prepareSteps,
  };

  const doRunSteps: Record<StakingAction, () => Promise<void>> = {
    stake: stakingStakeStepsStore.runSteps,
    unstake: stakingUnstakeStepsStore.runSteps,
    harvest: stakingHarvestStepsStore.runSteps,
    compound: stakingCompoundStepsStore.runSteps,
    claim: stakingClaimStepsStore.runSteps,
  };

  const stakingSteps = reactive<IStakingStepsState>({
    pool: undefined,
    action: undefined,
    steps: [],
    isShown: false,
    inProgress: false,
  });

  function $reset() {
    Object.entries(doReset).forEach(([, reset]) => reset());

    stakingSteps.pool = undefined;
    stakingSteps.action = undefined;
    stakingSteps.inProgress = false;
    // NOTE: see watch for isShown, steps
  }

  function setStaking(stakingPool: StakingPool, stakingAction: StakingAction) {
    stakingSteps.pool = stakingPool;
    stakingSteps.action = stakingAction;
  }

  function setIsShown(isShow: boolean) {
    if (!stakingSteps.action) return;

    doSetIsShown[stakingSteps.action](isShow);
  }

  function prepareSteps(): void {
    if (!stakingSteps.action) return;

    doPrepareSteps[stakingSteps.action]();
  }

  async function runSteps() {
    if (!stakingSteps.action) return;
    stakingSteps.inProgress = true;

    try {
      await doRunSteps[stakingSteps.action]();
    } finally {
      stakingSteps.inProgress = false;
    }
  }

  watch(
    () => (stakingSteps.action ? isStepsShown[stakingSteps.action]() : false),
    isShown => {
      stakingSteps.isShown = isShown;
    },
  );

  watch(
    () => (stakingSteps.action ? stakingStepsByAction[stakingSteps.action]() : []),
    steps => {
      stakingSteps.steps = steps;
    },
  );

  return {
    stakingSteps,
    setStaking,
    setIsShown,
    prepareSteps,
    runSteps,
    $reset,
  };
});
