export enum STEP_STATUS {
  ACTIVE = 'active',
  PROGRESS = 'progress',
  SUCCESS = 'success',
  ERROR = 'error',
  NULL = '',
}
export enum STEP_TYPE {
  BRIDGE = 'bridge',
  APPROVE = 'approve',
  APPROVE_LP = 'approveLP',
  SIGN = 'sign',
  REVERSE_APPROVE = 'reverseApprove',
  REVERSE_BRIDGE = 'reverseBridge',
}

export type StepInfo = {
  name?: string;
  type: STEP_TYPE;
  status: STEP_STATUS;
};
