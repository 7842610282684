import { defineStore } from 'pinia';
import { useMilkomedaWSCBridge } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCBridge';

export const usePortfoliosMilkomedaWSCBridge = defineStore('portfoliosMilkomedaWSCBridge', () => {
  const milkomedaWSCBridge = useMilkomedaWSCBridge();

  return {
    ...milkomedaWSCBridge,
  };
});
