import { getLiquidityDelegateAddress, getRouterAddress } from '@/helpers/address.helper';
import {
  getLiquidityDelegateContract,
  getRouterContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { safeDateNowPlusEstimatedMinutes } from '@/helpers/utils';
import { Token } from '@/sdk/entities/token';
import { applySlippageInPercents, toFixedWei, toWei } from '@/sdk/utils';
import { MODULE_NAMES } from '@/store';
import { SelectedPortfolioTokens } from '@/store/modules/portfolios/models/selected-portfolio-tokens.interface';
import { PORTFOLIO_ACTION_TYPES } from '@/store/modules/portfolios/portfolios.module';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { EasyModeAddLiquidityForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import BigNumber from 'bignumber.js';
import { useStore } from 'vuex';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { ethers } from 'ethers';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { getWithdrawMethodTokenParameters } from '@/helpers/portfolioWithdraw.helper';
import {
  DelegatedWithdrawParamsWithSignature,
  useSingleSideWithdrawTransaction,
} from '@/composables/single-side/useSingleSideWithdrawTransaction';

export function useEasyModeTransactions() {
  const store = useStore();
  const { easyModeForm } = useEasyModeForm();
  const { updateTokenBalances } = useBalances();
  const { getDelegatedWithdrawParams, signDelegateWithdrawParams } =
    useSingleSideWithdrawTransaction();

  async function addLiquidity(
    portfolioFormTokens: SelectedPortfolioTokens,
    account: string,
  ): Promise<ethers.providers.TransactionReceipt> {
    const tx = await store.dispatch(
      MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.PORTFOLIO_ADD_LIQUIDITY,
      {
        selectedTokens: portfolioFormTokens,
        portfolio: easyModeForm.portfolio,
        lpAmountOut: (easyModeForm as EasyModeAddLiquidityForm).lpAmountOut,
        destinationChainId: easyModeForm.destinationChainId,
        account,
        withDelegate: easyModeForm.useFarmsLp,
      },
    );

    return tx;
  }

  async function withdraw(
    withdrawTokens: { token: Token; amount: BigNumber }[],
    LPSum: BigNumber,
    account: string,
  ): Promise<ethers.providers.TransactionReceipt> {
    const { tokens, amountsOutInWei, amountsLPOutInWei } = getWithdrawMethodTokenParameters(
      withdrawTokens,
      LPSum,
      easyModeForm,
    );

    const minAmountsOut: string[] = amountsOutInWei.map(amount =>
      applySlippageInPercents(
        amount,
        store.state.portfolios.withdrawSettings.slippageTolerance,
      ).toFixed(0),
    );

    // CHECK_ALLOWANCE LP
    const lpToken: Token = new Token(
      easyModeForm.portfolio.baseToken.chainId,
      easyModeForm.portfolio.lpTokenAddress,
      18,
      'LPT',
    );

    await store.dispatch(
      MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.PORTFOLIO_CHECK_ALLOWANCE,
      {
        token: lpToken,
        amountInWei: toWei(LPSum),
        destinationChainId: easyModeForm.destinationChainId,
      },
    );

    // WITHDRAW
    console.log('removeLiquidityFromPortfolio', [
      withdrawTokens.map(withdrawToken => withdrawToken.token.address),
      amountsLPOutInWei,
      minAmountsOut,
      account,
      safeDateNowPlusEstimatedMinutes(store.state.portfolios.withdrawSettings.transactionDeadline),
      easyModeForm.portfolio.contractAddress,
      easyModeForm.portfolio.type === PortfolioSource.PORTFOLIO_LOCALE
        ? 0
        : easyModeForm.destinationChainId,
    ]);
    console.log('minAmountsOut of tokens and decimals', [
      withdrawTokens.map(withdrawToken => withdrawToken.token.symbol),
      withdrawTokens.map(withdrawToken => withdrawToken.token.decimals),
      minAmountsOut,
    ]);

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'removeLiquidityFromPortfolio(address[],uint256[],uint256[],address,uint256,address,uint256)',
      [
        tokens.map(token => token.address),
        amountsLPOutInWei,
        minAmountsOut,
        account,
        safeDateNowPlusEstimatedMinutes(
          store.state.portfolios.withdrawSettings.transactionDeadline,
        ),
        easyModeForm.portfolio.contractAddress,
        easyModeForm.portfolio.type === PortfolioSource.PORTFOLIO_LOCALE
          ? 0
          : easyModeForm.destinationChainId,
      ],
    );

    const tx = await result.wait();

    return tx;
  }

  async function delegateWithdraw(
    withdrawTokens: { token: Token; amount: BigNumber }[],
    LPSum: BigNumber,
    account: string,
  ) {
    //: Promise<ethers.providers.TransactionReceipt>
    const { tokens, amountsOutInWei, amountsLPOutInWei } = getWithdrawMethodTokenParameters(
      withdrawTokens,
      LPSum,
      easyModeForm,
    );

    const minAmountsOut: string[] = amountsOutInWei.map(amount =>
      applySlippageInPercents(
        amount,
        store.state.portfolios.withdrawSettings.slippageTolerance,
      ).toFixed(0),
    );

    // MAKE sig
    const lpAmountInFarm = toFixedWei(toWei(LPSum.minus(easyModeForm.portfolio.getBalanceOfLp())));
    // 18 DEC??????
    console.log('lpAmountInFarm', lpAmountInFarm.toString());

    // WITHDRAW

    const farm = store.state.farming.lp[easyModeForm.portfolio.lpTokenAddress];
    const delegatedWithdrawParams = await getDelegatedWithdrawParams({
      lpAmountFromFarmInWei: lpAmountInFarm.toString(),
      portfolioAddress: easyModeForm.portfolio.contractAddress,
      farmAddress: farm.farm,
    });
    const delegatedWithdrawParamsWithSignature: DelegatedWithdrawParamsWithSignature =
      await signDelegateWithdrawParams(delegatedWithdrawParams);

    const delegateContract = getLiquidityDelegateContract(
      getLiquidityDelegateAddress(),
      getInstance().web3.getSigner(),
    );

    // CHECK_ALLOWANCE LP
    const lpToken: Token = new Token(
      easyModeForm.portfolio.baseToken.chainId,
      easyModeForm.portfolio.lpTokenAddress,
      18,
      'LPT',
    );

    await store.dispatch(
      MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.PORTFOLIO_CHECK_ALLOWANCE,
      {
        token: lpToken,
        amountInWei: toWei(easyModeForm.portfolio.getBalanceOfLp()),
        destinationChainId: easyModeForm.destinationChainId,
        sendApprove: true,
        withDelegate: true,
      },
    );

    console.log('delegateWithdraw', [
      withdrawTokens.map(withdrawToken => withdrawToken.token.address),
      amountsLPOutInWei,
      minAmountsOut,
      account,
      safeDateNowPlusEstimatedMinutes(store.state.portfolios.withdrawSettings.transactionDeadline),
      delegatedWithdrawParamsWithSignature,
    ]);

    const result = await transactionWithEstimatedGas(delegateContract, 'delegateWithdraw', [
      tokens.map(token => token.address),
      amountsLPOutInWei,
      minAmountsOut,
      account,
      safeDateNowPlusEstimatedMinutes(store.state.portfolios.withdrawSettings.transactionDeadline),
      delegatedWithdrawParamsWithSignature,
    ]);

    const tx = await result.wait();

    return tx;
  }

  async function updatePortfolios() {
    await store.dispatch(MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.INIT_PORTFOLIOS);
    await updateTokenBalances();
  }

  return {
    addLiquidity,
    withdraw,
    delegateWithdraw,
    updatePortfolios,
  };
}
