import { defineStore } from 'pinia';
import { useMilkomedaWSCUnwrapBridge } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCUnwrapBridge';

export const useSwapMilkomedaWSCUnwrapBridge = defineStore('swapMilkomedaWSCUnwrapBridge', () => {
  const milkomedaWSCUnwrapBridge = useMilkomedaWSCUnwrapBridge();

  return {
    ...milkomedaWSCUnwrapBridge,
  };
});
