import { defineStore } from 'pinia';
import { ref } from 'vue';
import { SLIPPAGE_TOLERANCE, TRANSACTION_DEADLINE } from '@/helpers/constants';
import { SingleSideTransactionSettings } from './models/single-side-settings';

export const useSingleSide = defineStore('singleSide', () => {
  const settings = ref<SingleSideTransactionSettings>({
    slippageTolerance: SLIPPAGE_TOLERANCE.toString(),
    transactionDeadline: TRANSACTION_DEADLINE.toString(),
  });

  const setSlippageTolerance = (slippageTolerance: string): void => {
    settings.value.slippageTolerance = slippageTolerance;
  };

  const setTransactionDeadline = (transactionDeadline: string): void => {
    settings.value.transactionDeadline = transactionDeadline;
  };

  const setTransactionSettings = (newSettings: SingleSideTransactionSettings): void => {
    settings.value = Object.assign(settings.value, newSettings);
  };

  return {
    settings,
    setTransactionSettings,
    setSlippageTolerance,
    setTransactionDeadline,
  };
});
