import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { safeDateNowPlusEstimatedMinutes, safeParseUnits } from '@/helpers/utils';
import { ISwapForm } from './models/swap-form.interface';
import { Token } from '@/sdk/entities/token';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';

type swapFn = (
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
) => Promise<ethers.providers.TransactionResponse>;

type SwapTypeWhenOutputEstimated =
  | 'EXACT_ETH_FOR_ETH'
  | 'EXACT_ETH_FOR_TOKEN'
  | 'EXACT_TOKEN_FOR_ETH'
  | 'EXACT_TOKEN_FOR_TOKEN';
function getSwapTypeWhenOutputEstimated(swapForm: ISwapForm): SwapTypeWhenOutputEstimated {
  if (swapForm.input.token?.isETHToken()) {
    if (isETHTokenInDestinationChain(swapForm.output.token)) {
      return 'EXACT_ETH_FOR_ETH';
    }
    return 'EXACT_ETH_FOR_TOKEN';
  }

  if (swapForm.output.token?.isETHToken() || isETHTokenInDestinationChain(swapForm.output.token)) {
    return 'EXACT_TOKEN_FOR_ETH';
  }

  return 'EXACT_TOKEN_FOR_TOKEN';
}

type NotWSCSwapTypeWhenInputEstimated =
  | 'ETH_FOR_EXACT_TOKEN'
  | 'TOKEN_FOR_EXACT_ETH'
  | 'TOKEN_FOR_EXACT_TOKEN';
type WSCSwapTypeWhenInputEstimated =
  | 'EXACT_ETH_FOR_TOKEN'
  | 'EXACT_TOKEN_FOR_ETH'
  | 'EXACT_TOKEN_FOR_TOKEN';
type SwapTypeWhenInputEstimated = NotWSCSwapTypeWhenInputEstimated | WSCSwapTypeWhenInputEstimated;

function getSwapTypeWhenInputEstimated(swapForm: ISwapForm): SwapTypeWhenInputEstimated {
  if (swapForm.input.token?.isETHToken()) {
    return ENABLE_FAKE_CARDANO_NETWORK ? 'EXACT_ETH_FOR_TOKEN' : 'ETH_FOR_EXACT_TOKEN';
  }

  if (swapForm.output.token?.isETHToken()) {
    return ENABLE_FAKE_CARDANO_NETWORK ? 'EXACT_TOKEN_FOR_ETH' : 'TOKEN_FOR_EXACT_ETH';
  }

  return ENABLE_FAKE_CARDANO_NETWORK ? 'EXACT_TOKEN_FOR_TOKEN' : 'TOKEN_FOR_EXACT_TOKEN';
}

export const swapMethod: Record<SwapTypeWhenInputEstimated | SwapTypeWhenOutputEstimated, swapFn> =
  {
    // input estimated
    ETH_FOR_EXACT_TOKEN: (swapForm, account, routerContract) => {
      console.log('swapETHForExactTokensWithPortfolios');
      return swapETHForExactTokensWithPortfolios(swapForm, account, routerContract);
    },
    TOKEN_FOR_EXACT_ETH: (swapForm, account, routerContract) => {
      console.log('swapTokensForExactETHWithPortfolios');
      return swapTokensForExactETHWithPortfolios(swapForm, account, routerContract);
    },
    TOKEN_FOR_EXACT_TOKEN: (swapForm, account, routerContract) => {
      console.log('swapTokensForExactTokensWithPortfolios');
      return swapTokensForExactTokensWithPortfolios(swapForm, account, routerContract);
    },
    // 1. output estimated
    // 2. input estimated and ENABLE_FAKE_CARDANO_NETWORK
    EXACT_ETH_FOR_ETH: (swapForm, account, routerContract) => {
      console.log('swapExactETHForETHWithPortfolios');
      return swapExactETHForETHWithPortfolios(swapForm, account, routerContract);
    },
    EXACT_ETH_FOR_TOKEN: (swapForm, account, routerContract) => {
      console.log('swapExactETHForTokensWithPortfolios');
      return swapExactETHForTokensWithPortfolios(swapForm, account, routerContract);
    },
    EXACT_TOKEN_FOR_ETH: (swapForm, account, routerContract) => {
      console.log('swapExactTokensForETHWithPortfolios');
      return swapExactTokensForETHWithPortfolios(swapForm, account, routerContract);
    },
    EXACT_TOKEN_FOR_TOKEN: (swapForm, account, routerContract) => {
      console.log('swapExactTokensForTokensWithPortfolios');
      return swapExactTokensForTokensWithPortfolios(swapForm, account, routerContract);
    },
  };

export function getSwapType(
  swapForm: ISwapForm,
): SwapTypeWhenInputEstimated | SwapTypeWhenOutputEstimated {
  if (swapForm.output.estimated) {
    console.log('output estimated', swapForm.input.token);
    return getSwapTypeWhenOutputEstimated(swapForm);
  } else if (swapForm.input.estimated) {
    /**
     * NOTE: cross-chain swap is always `swapForm.output.estimated`
     * see: onSwapFormChange
     */
    console.log('input estimated', swapForm.output.token);
    return getSwapTypeWhenInputEstimated(swapForm);
  }

  return 'EXACT_TOKEN_FOR_TOKEN';
}

export async function swapETHForWETH(
  swapForm: ISwapForm,
  wethContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const overrides = {
    value: inputAmount,
  };
  return transactionWithEstimatedGas(wethContract, 'deposit', [], overrides);
}

export async function swapWETHForETH(
  swapForm: ISwapForm,
  wethContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();

  return transactionWithEstimatedGas(wethContract, 'withdraw', [inputAmount]);
}
async function swapExactETHForETHWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const minAmountOut = safeParseUnits(
    new BigNumber(swapForm.output.amount || 1)
      .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.output.token?.decimals,
  );
  const overrides = {
    value: inputAmount,
  };

  console.log('swapExactETHForETHWithPortfolios:params: ', {
    amountOutMin: minAmountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    overrides: overrides,
  });

  return transactionWithEstimatedGas(
    routerContract,
    'swapExactETHForETHWithPortfolios',
    [
      minAmountOut,
      swapForm.bestTrade.route.path,
      account,
      safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
      swapForm.bestTrade.route.portfolios,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    ],
    overrides,
  );
}

async function swapExactETHForTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const minAmountOut = safeParseUnits(
    new BigNumber(swapForm.output.amount || 1)
      .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.output.token?.decimals,
  );
  const overrides = {
    value: inputAmount,
  };

  console.log('swapExactETHForTokensWithPortfolios:params: ', {
    amountOutMin: minAmountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    overrides: overrides,
  });

  return transactionWithEstimatedGas(
    routerContract,
    'swapExactETHForTokensWithPortfolios',
    [
      minAmountOut,
      swapForm.bestTrade.route.path,
      account,
      safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
      swapForm.bestTrade.route.portfolios,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    ],
    overrides,
  );
}

async function swapExactTokensForETHWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const minAmountOut = safeParseUnits(
    new BigNumber(swapForm.output.amount || 1)
      .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.output.token?.decimals,
  );

  console.log('swapExactTokensForETHWithPortfolios:params: ', {
    amountIn: inputAmount,
    amountOutMin: minAmountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapExactTokensForETHWithPortfolios', [
    inputAmount,
    minAmountOut,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}

async function swapExactTokensForTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const minAmountOut = safeParseUnits(
    new BigNumber(swapForm.output.amount || 1)
      .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.output.token?.decimals,
  );

  console.log('swapExactTokensForTokensWithPortfolios:params: ', {
    amountIn: inputAmount,
    amountOutMin: minAmountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapExactTokensForTokensWithPortfolios', [
    inputAmount,
    minAmountOut,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}

async function swapETHForExactTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const maxAmountIn = safeParseUnits(
    new BigNumber(swapForm.input.amount || 0)
      .multipliedBy(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.input.token?.decimals,
  );
  const overrides = {
    value: maxAmountIn,
  };

  console.log('swapETHForExactTokensWithPortfolios:params: ', {
    amountOut: amountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    overrides: overrides,
  });

  return transactionWithEstimatedGas(
    routerContract,
    'swapETHForExactTokensWithPortfolios',
    [
      amountOut,
      swapForm.bestTrade.route.path,
      account,
      safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
      swapForm.bestTrade.route.portfolios,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    ],
    overrides,
  );
}

async function swapTokensForExactETHWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const maxAmountIn = safeParseUnits(
    new BigNumber(swapForm.input.amount || 0)
      .multipliedBy(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.input.token?.decimals,
  );

  console.log('swapTokensForExactETHWithPortfolios:params: ', {
    amountOut: amountOut,
    maxAmountIn: maxAmountIn,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapTokensForExactETHWithPortfolios', [
    amountOut,
    maxAmountIn,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}

async function swapTokensForExactTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const maxAmountIn = safeParseUnits(
    new BigNumber(swapForm.input.amount || 0)
      .multipliedBy(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.input.token?.decimals,
  );

  console.log('swapTokensForExactTokensWithPortfolios:params: ', {
    amountOut: amountOut,
    maxAmountIn: maxAmountIn,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapTokensForExactTokensWithPortfolios', [
    amountOut,
    maxAmountIn,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}

// Helpers
function isETHTokenInDestinationChain(token: Token | null): boolean {
  if (!token) return false;
  return !token.isWETHToken() && token.isBaseToken();
}
