import _ from 'lodash';
import { computed, reactive, watch } from 'vue';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { fromWei, toWei } from '@/sdk/utils';
import { calculateGasForReversBridge } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { Token } from '@/sdk/entities/token';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useMilkomedaWSCBridgeCalculations } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCBridgeCalculations';
import { useMilkomedaWSCUnwrapBridgeCalculations } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCUnwrapBridgeCalculations';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export interface IMilkomedaWSCBalanceADAIntoCardanoValidatorState {
  isValidCardanoBalance: boolean;
  minADABalance: string | null;
}

export function useMilkomedaWSCBalanceADAIntoCardanoValidator() {
  const { getTokenBySymbolAndChainId, isPresentTokenIntoNetwork } = useTokens();
  const {
    milkomedaWSCBridgeState,
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useMilkomedaWSCBridgeCalculations();
  const {
    setBridgeTokensFromMilkomeda,
    setIsEVMFromMilkomeda,
    $reset: resetMilkomedaWSCUnwrapBridge,
  } = useMilkomedaWSCUnwrapBridgeCalculations();

  const milkomedaWSCBalanceADAIntoCardanoValidatorState =
    reactive<IMilkomedaWSCBalanceADAIntoCardanoValidatorState>({
      isValidCardanoBalance: true,
      minADABalance: null,
    });

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  function $reset(): void {
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();
    milkomedaWSCBalanceADAIntoCardanoValidatorState.isValidCardanoBalance = true;
    milkomedaWSCBalanceADAIntoCardanoValidatorState.minADABalance = null;
  }

  function checkBalanceADAForBridgeOnlyADAFromCardano() {
    const token = cardanoADATokenForBridge.value!;
    const amountInWei = toWei('0', token.decimals).toString();

    return checkBalanceADAForBridgeFromCardano(amountInWei, token);
  }

  function checkBalanceADAForBridgeFromCardano(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();

    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(false);
    const bridgeTokens: BridgeToken[] = [
      {
        amount: fromWei(amountInWei, token.decimals).toString(),
        token,
      },
    ];

    setBridgeTokensFromCardano(bridgeTokens);
  }

  function checkBalanceADAForBridgeFromMilkomeda(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();

    const bridgeTokens: BridgeToken[] = [];
    if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
      bridgeTokens.push({
        amount: fromWei(amountInWei, token.decimals).toString(),
        token: token,
      });
    }

    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(!!bridgeTokens.length);

    if (bridgeTokens.length) {
      setIsEVMFromMilkomeda(false);
      setBridgeTokensFromMilkomeda(bridgeTokens);
    } else {
      resetMilkomedaWSCUnwrapBridge();
    }

    const gas = calculateGasForReversBridge(cardanoADATokenForBridge.value, bridgeTokens);
    setBridgeTokensFromCardano(
      gas ? [gas] : [{ amount: '0', token: cardanoADATokenForBridge.value }],
    );
  }

  watch(
    [() => milkomedaWSCBridgeState.needBridge, () => milkomedaWSCBridgeState.isValidCardanoBalance],
    ([needBridge, isValidCardanoBalance]) => {
      if (!needBridge) {
        milkomedaWSCBalanceADAIntoCardanoValidatorState.isValidCardanoBalance = true;

        LOGGER.log(
          '[WSC:VALIDATE]: state validate ADA balance into Cardano : ',
          _.cloneDeep(milkomedaWSCBridgeState),
        );
        return;
      }
      milkomedaWSCBalanceADAIntoCardanoValidatorState.isValidCardanoBalance = isValidCardanoBalance;

      LOGGER.log(
        '[WSC:VALIDATE]: state validate ADA balance into Cardano : ',
        _.cloneDeep(milkomedaWSCBridgeState),
      );
    },
    {
      immediate: true,
    },
  );

  watch(
    () => milkomedaWSCBridgeState.minADABalance,
    minADABalance => {
      milkomedaWSCBalanceADAIntoCardanoValidatorState.minADABalance = minADABalance;

      LOGGER.log(
        '[WSC:VALIDATE]: state validate ADA balance into Cardano : ',
        _.cloneDeep(milkomedaWSCBridgeState),
      );
    },
    {
      immediate: true,
    },
  );

  return {
    milkomedaWSCBalanceADAIntoCardanoValidatorState,
    checkBalanceADAForBridgeOnlyADAFromCardano,
    checkBalanceADAForBridgeFromCardano,
    checkBalanceADAForBridgeFromMilkomeda,
    $reset,
  };
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].WSC;
}
