import BigNumber from 'bignumber.js';
import { BIG_ZERO, max } from '@/utils/bigNumber';
import { Token } from '@/sdk/entities/token';

export function calculateAmountForBridge(
  fromAmountInToken: BigNumber,
  tokenBalanceIntoMilkomedaInToken: BigNumber = BIG_ZERO,
  token: Token,
) {
  const amountBridge = max(fromAmountInToken.minus(tokenBalanceIntoMilkomedaInToken), BIG_ZERO);

  console.log(`[HELPER:CALC] Calc bridge amount [ ${token.symbol} ] : `, amountBridge.toString());

  return amountBridge;
}
