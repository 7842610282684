import { defineStore } from 'pinia';
import { useSwapSteps } from '@/store/modules/swap/useSwapSteps';
import { useEasyModeAddLiquiditySteps } from '@/store/modules/portfolios/easy-mode/add-liquidity/useEasyModeAddLiquiditySteps';
import { useEasyModeWithdrawSteps } from '@/store/modules/portfolios/easy-mode/withdraw/useEasyModeWithdrawSteps';
import { ref, watch } from 'vue';
import { toRef } from '@vueuse/core';
import { useFarmingSteps } from '@/store/modules/farming/useFarmingSteps';
import { useStakingSteps } from '@/store/modules/staking/useStakingSteps';

export const useAllSteps = defineStore('all-steps', () => {
  const { swapSteps } = useSwapSteps();
  const { addLiquiditySteps } = useEasyModeAddLiquiditySteps();
  const { withdrawSteps } = useEasyModeWithdrawSteps();
  const { farmingSteps } = useFarmingSteps();
  const { stakingSteps } = useStakingSteps();

  const isInProgress = ref({ state: false });
  const swapStepsRef = toRef(swapSteps, 'inProgress');
  const addLiquidityStepsRef = toRef(addLiquiditySteps, 'inProgress');
  const withdrawStepsRef = toRef(withdrawSteps, 'inProgress');
  const farmingStepsRef = toRef(farmingSteps, 'inProgress');
  const stakingStepsRef = toRef(stakingSteps, 'inProgress');

  watch(
    [swapStepsRef, addLiquidityStepsRef, withdrawStepsRef, farmingStepsRef, stakingStepsRef],
    value => {
      isInProgress.value.state = value.some(inProgress => inProgress);
      console.log('has WSC tx in progress : ', isInProgress.value.state);
    },
  );

  return {
    isInProgress,
  };
});
