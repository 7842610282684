import { ethers } from 'ethers';
import { useStore } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { getAutoPoolContract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { AUTO_STAKING_ACTION_TYPES } from '@/store/modules/staking/autoStaking.module';

export function useAutoStakingTransactions() {
  const store = useStore();
  const { updateTokenBalances } = useBalances();

  async function stake(amountInWei: string): Promise<ethers.providers.TransactionReceipt> {
    const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());

    const callArgs = {
      _amount: amountInWei,
    };

    console.groupCollapsed('[AUTO:POOL:STAKE] deposit : ', autoStakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      autoStakingContract,
      'deposit',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function unstake(amountInWei: string): Promise<ethers.providers.TransactionReceipt> {
    const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());

    const callArgs = {
      _amount: amountInWei,
    };

    console.groupCollapsed('[AUTO:POOL:UNSTAKE] withdraw : ', autoStakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      autoStakingContract,
      'withdraw',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function unstakeAll(): Promise<ethers.providers.TransactionReceipt> {
    const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());

    const callArgs = {};

    console.groupCollapsed('[AUTO:POOL:UNSTAKE:ALL] withdrawAll : ', autoStakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      autoStakingContract,
      'withdrawAll',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function claim(): Promise<ethers.providers.TransactionReceipt> {
    const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());

    const callArgs = {};

    console.groupCollapsed('[AUTO:POOL:CLAIM] claim : ', autoStakingContract.address);
    console.table(callArgs);
    console.groupEnd();

    const result = await transactionWithEstimatedGas(
      autoStakingContract,
      'claim',
      Object.values(callArgs),
    );

    const tx = await result.wait();

    await updatePools();

    return tx;
  }

  async function updatePools() {
    await updateTokenBalances();
    await store.dispatch(
      MODULE_NAMES.AUTO_STAKING + '/' + AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING,
    );
    await store.dispatch(MODULE_NAMES.AUTO_STAKING + '/' + AUTO_STAKING_ACTION_TYPES.UPDATE_INFO);
    await store.dispatch(
      MODULE_NAMES.AUTO_STAKING + '/' + AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING,
    );
  }

  return {
    stake,
    unstake,
    unstakeAll,
    claim,
    updatePools,
  };
}
