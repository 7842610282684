import { defineStore } from 'pinia';
import { useMilkomedaWSCUnwrapBridge } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCUnwrapBridge';

export const usePortfoliosMilkomedaWSCUnwrapBridge = defineStore(
  'portfoliosMilkomedaWSCUnwrapBridge',
  () => {
    const milkomedaWSCUnwrapBridge = useMilkomedaWSCUnwrapBridge();

    return {
      ...milkomedaWSCUnwrapBridge,
    };
  },
);
