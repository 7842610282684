import { defineStore } from 'pinia';
import { reactive, watch } from 'vue';
import { useFarmingStakeSteps } from './stake/useFarmingStakeSteps';
import { useFarmingUnstakeSteps } from './unstake/useFarmingUnstakeSteps';
import { useFarmingHarvestSteps } from './harvest/useFarmingHarvestSteps';
import { FarmingAction } from './models/farming-action';
import { StepInfo } from '@/components/stepper/stepper.types';

interface IFarmingStepsState {
  action?: FarmingAction;
  steps: StepInfo[];
  isShown: boolean;
  inProgress: boolean;
}

export const useFarmingSteps = defineStore('farmingSteps', () => {
  const farmingStakeStepsStore = useFarmingStakeSteps();
  const farmingUnstakeStepsStore = useFarmingUnstakeSteps();
  const farmingHarvestStepsStore = useFarmingHarvestSteps();

  const farmingStepsByAction: Record<FarmingAction, () => StepInfo[]> = {
    stake: () => farmingStakeStepsStore.farmingStakeSteps.steps,
    unstake: () => farmingUnstakeStepsStore.farmingUnstakeSteps.steps,
    harvest: () => farmingHarvestStepsStore.farmingHarvestSteps.steps,
  };

  const isStepsShown: Record<FarmingAction, () => boolean> = {
    stake: () => farmingStakeStepsStore.farmingStakeSteps.isShown,
    unstake: () => farmingUnstakeStepsStore.farmingUnstakeSteps.isShown,
    harvest: () => farmingHarvestStepsStore.farmingHarvestSteps.isShown,
  };

  const doReset: Record<FarmingAction, () => void> = {
    stake: farmingStakeStepsStore.$reset,
    unstake: farmingUnstakeStepsStore.$reset,
    harvest: farmingHarvestStepsStore.$reset,
  };

  const doSetIsShown: Record<FarmingAction, (isShow: boolean) => void> = {
    stake: (isShow: boolean) => (farmingStakeStepsStore.farmingStakeSteps.isShown = isShow),
    unstake: (isShow: boolean) => (farmingUnstakeStepsStore.farmingUnstakeSteps.isShown = isShow),
    harvest: (isShow: boolean) => (farmingHarvestStepsStore.farmingHarvestSteps.isShown = isShow),
  };

  const doPrepareSteps: Record<FarmingAction, () => void> = {
    stake: farmingStakeStepsStore.prepareSteps,
    unstake: farmingUnstakeStepsStore.prepareSteps,
    harvest: farmingHarvestStepsStore.prepareSteps,
  };

  const doRunSteps: Record<FarmingAction, () => Promise<void>> = {
    stake: farmingStakeStepsStore.runSteps,
    unstake: farmingUnstakeStepsStore.runSteps,
    harvest: farmingHarvestStepsStore.runSteps,
  };

  const farmingSteps = reactive<IFarmingStepsState>({
    action: undefined,
    steps: [],
    isShown: false,
    inProgress: false,
  });

  function $reset() {
    Object.entries(doReset).forEach(([, reset]) => reset());

    farmingSteps.action = undefined;
    farmingSteps.inProgress = false;
  }

  function setFarmingAction(farmingAction: FarmingAction) {
    farmingSteps.action = farmingAction;
  }

  function setIsShown(isShow: boolean) {
    if (!farmingSteps.action) return;

    doSetIsShown[farmingSteps.action](isShow);
  }

  function prepareSteps(): void {
    if (!farmingSteps.action) return;

    doPrepareSteps[farmingSteps.action]();
  }

  async function runSteps() {
    if (!farmingSteps.action) return;
    farmingSteps.inProgress = true;

    try {
      await doRunSteps[farmingSteps.action]();
    } finally {
      farmingSteps.inProgress = false;
    }
  }

  watch(
    () => (farmingSteps.action ? isStepsShown[farmingSteps.action]() : false),
    isShown => {
      farmingSteps.isShown = isShown;
    },
  );

  watch(
    () => (farmingSteps.action ? farmingStepsByAction[farmingSteps.action]() : []),
    steps => {
      farmingSteps.steps = steps;
    },
  );

  return {
    farmingSteps,
    setFarmingAction,
    setIsShown,
    prepareSteps,
    runSteps,
    $reset,
  };
});
