import { ethers } from 'ethers';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { SignatureLike } from '@ethersproject/bytes';

type SignResponse = Promise<SignatureLike>;
type CommonSignTypedDataFunction = (
  send: (arg: any) => any,
  args: { method: string; params: any[] },
) => SignResponse;

const PROVIDER_SEND_FN_ADAPTER: Record<string, CommonSignTypedDataFunction> = {
  nufi: async (
    send: (arg: any) => Promise<string>,
    args: { method: string; params: any[] },
  ): SignResponse => {
    const signatureLike = await send(args);
    return signatureLike;
  },
  DEFAULT: async (
    send: (...arg: any) => Promise<{ result: string }>,
    args: { method: string; params: any[] },
  ): SignResponse => {
    const response = await send(args.method, args.params);

    return response.result;
  },
};

export const EMPTY_SIGNATURE: Readonly<ethers.Signature> = Object.freeze(
  ethers.utils.splitSignature({ v: 0, r: '0x', s: '0x' } as SignatureLike),
);

/**
 * EIP712Domain definition.
 *
 * The refers to the domain the verifying contract is hosted on.
 */
export const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
];

export function useWalletSignature() {
  // https://docs.metamask.io/wallet/how-to/sign-data/#use-eth_signtypeddata_v4
  /**
   * This provide the most human-readable signatures.
   * It follows the EIP-712 specification to allow users to sign typed structured data.
   *
   * @param account address who sign data
   * @param data typed structured data in string format
   * @returns signature in full expanded-format: { v, r, s }
   */
  async function createSignature(account: string, data: string): Promise<ethers.Signature> {
    const SIGN_TYPED_DATA_METHOD = 'eth_signTypedData_v4';

    console.groupCollapsed(`[CREATE:SIGN:TYPED:DATA:V4] ${SIGN_TYPED_DATA_METHOD}`);

    const provider = getInstance().web3.provider;
    const provideName = provider.info?.name?.toLowerCase();
    console.log('[CREATE:SIGN:TYPED:DATA:V4] provider name : ', provideName);

    try {
      console.log('[CREATE:SIGN:TYPED:DATA:V4] params : ', {
        account,
        data,
      });

      const signTypedData =
        PROVIDER_SEND_FN_ADAPTER[provideName] ?? PROVIDER_SEND_FN_ADAPTER.DEFAULT;

      const signatureLike = await signTypedData(provider.send, {
        method: SIGN_TYPED_DATA_METHOD,
        params: [account, data],
      });

      return ethers.utils.splitSignature(signatureLike);
    } catch (error) {
      console.error('[CREATE:SIGN:TYPED:DATA:V4] Error : ', error);

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  return {
    createSignature,
  };
}
