<template>
  <div :class="containerClass" @click="onClick($event)" :style="style" :disabled="disabled">
    <div class="p-hidden-accessible">
      <input
        ref="input"
        type="checkbox"
        :checked="checked"
        v-bind="$attrs"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        @keydown.enter.prevent="onClick($event)"
        role="switch"
        :aria-checked="checked"
      />
    </div>
    <div
      class="inputswitch-slider flex h-full align-items-center justify-content-between p-1"
      :data-label="checked ? labelOn : labelOff"
    >
      <template v-if="labelOff && labelOn">
        <span class="inputswitch-label label-off">{{ labelOff }}</span>
        <span class="inputswitch-label label-on">{{ labelOn }}</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchInput',
  inheritAttrs: false,
  emits: ['click', 'update:modelValue', 'change', 'input'],
  props: {
    modelValue: {
      type: null,
      default: false,
    },
    class: null,
    style: null,
    trueValue: {
      type: null,
      default: true,
    },
    falseValue: {
      type: null,
      default: false,
    },
    labelOff: { type: String, default: '' },
    labelOn: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onClick(event) {
      if (!this.$attrs.disabled) {
        const newValue = this.checked ? this.falseValue : this.trueValue;
        this.$emit('click', event);
        this.$emit('update:modelValue', newValue);
        this.$emit('change', event);
        this.$emit('input', newValue);
        this.$refs.input.focus();
      }
      event.preventDefault();
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
  computed: {
    containerClass() {
      return [
        'inputswitch p-component',
        this.class,
        {
          'inputswitch-checked': this.checked,
          'p-disabled': this.$attrs.disabled,
          'p-focus': this.focused,
        },
      ];
    },
    checked() {
      return this.modelValue === this.trueValue;
    },
  },
};
</script>

<style scoped lang="scss">
.inputswitch {
  width: 2.8rem;
  height: 1.5rem;
  position: relative;
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: 300;
}
.inputswitch .inputswitch-slider {
  position: relative;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 1.5rem;
  background: var(--switch-bg-color);
}
.inputswitch .inputswitch-slider:before {
  content: '';
  position: absolute;
  top: 50%;
  width: 1rem;
  height: 1rem;
  left: 0.25rem;
  margin-top: -0.5rem;
  border-radius: 50%;
  transition-duration: 0.2s;
  background: var(--switch-off-color);
}
.inputswitch.inputswitch-checked .inputswitch-slider:before {
  right: 0.25rem;
  transform: translateX(1.25rem);
}
.inputswitch.focus .inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
}
.inputswitch.inputswitch-checked .inputswitch-slider {
  background: var(--switch-on-bg-color);
}
.inputswitch.inputswitch-checked .inputswitch-slider:before {
  background: var(--switch-on-bg-color);
}
.inputswitch.invalid {
  border-color: var(--danger);
}
.inputswitch {
  &.inputswitch-checked .inputswitch-slider:before {
    background: var(--switch-on-color);
    box-shadow: $shadow-xs;
    color: var(--text-color);
  }
}
.switch-lg {
  .inputswitch {
    min-width: 7rem;
    width: unset;
    height: $control-height;
  }
  .inputswitch .inputswitch-slider:before {
    content: attr(data-label);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-weight: 300;
    width: 50%;
    height: 2rem;
    left: 0.25rem;
    margin-top: -1rem;
    border-radius: 1rem;
  }
  .inputswitch-label {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    white-space: nowrap;
    transition: color 0.2s, opacity 0.2s;
    &.label-off {
      opacity: 0;
    }
  }
  .inputswitch.inputswitch-checked {
    .inputswitch-slider:before {
      transform: translateX(calc(100% - 0.5rem));
      color: var(--text-color);
    }
    .inputswitch-label {
      &.label-off {
        opacity: 1;
      }
    }
  }
}
.switch-tabs {
  &.inputswitch.inputswitch-checked .inputswitch-slider {
    background: var(--switch-bg-color);
  }
  &.inputswitch .inputswitch-slider[data-v-2bd781ea]:before {
    background: var(--switch-on-color);
  }
}
</style>
