import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { computed, reactive, ref, watch } from 'vue';
import { CardanoWallet } from 'crypto-sdk';
import { fromWei } from '@/sdk/utils';
import { BIG_ONE, BIG_ZERO, max, min } from '@/utils/bigNumber';
import { APP_NETWORK_NAME, DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { calculateAmountForBridge } from '@/helpers/bridge-calculations.helper';
import {
  ADA_FOR_MILKOMEDA_WHEN_CARDANO,
  ADA_FOR_MILKOMEDA_WHEN_EVM,
  BRIDGE_ADA_FOR_MILKOMEDA_WHEN_CARDANO,
  BRIDGE_ADA_FOR_MILKOMEDA_WHEN_EVM,
  MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA,
  SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA,
  TX_FEE_CARDANO_IN_ADA,
  calculateMaxAmountADAIntoCardanoAndMilkomeda,
  calculateMinADABalanceToCardano,
  normalizeAmountByMinDecimals,
} from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { Token } from '@/sdk/entities/token';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { IMilkomedaWSCBridgeState } from '@/composables/milkomeda-wrapped-smartcontract/models/milkomeda-wsc-bridge-state.interface';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export function useMilkomedaWSCBridgeCalculations() {
  const { isPresentTokenIntoNetwork, getTokenBySymbolAndChainId } = useTokens();
  const { balanceByTokenSymbolAndChainId } = useBalances();
  const { walletState, getProviderBy } = useWallet();

  const input = ref<BridgeToken[]>([]);
  const isEVM = ref(false);
  const hasBridgeFromMilkomeda = ref(false);
  const isUnwrapADA = ref(false);
  const hasADA = ref(false);

  const milkomedaWSCBridgeState = reactive<IMilkomedaWSCBridgeState>({
    input: {
      tokens: [],
      ADA: null,
    },
    gas: null,
    bridge: [],
    needBridge: false,
    isValidCardanoBalance: false,
    minADABalance: null,
  });

  function setBridgeTokensFromCardano(tokens: BridgeToken[]): void {
    milkomedaWSCBridgeState.input.tokens = [];
    milkomedaWSCBridgeState.input.ADA = null;
    input.value = tokens;
  }

  function setIsEVMFromCardano(willToEVM: boolean): void {
    isEVM.value = willToEVM;
  }

  function setHasBridgeFromMilkomeda(willBridgeFromMilkomeda: boolean): void {
    hasBridgeFromMilkomeda.value = willBridgeFromMilkomeda;
  }

  function setIsUnwrapADAFromMilkomeda(isUnwrapADAFromMilkomeda: boolean): void {
    isUnwrapADA.value = isUnwrapADAFromMilkomeda;
  }

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  const milkomedaADAToken = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_NETWORK_ID!);
  });

  const amountMinADAIntoMilkomedaWhenCheckBridge = computed(() => {
    return isEVM.value || !hasBridgeFromMilkomeda.value
      ? BigNumber(ADA_FOR_MILKOMEDA_WHEN_EVM)
      : BigNumber(ADA_FOR_MILKOMEDA_WHEN_CARDANO);
  });

  const amountADAForBridge = computed(() => {
    return isEVM.value || !hasBridgeFromMilkomeda.value
      ? BigNumber(BRIDGE_ADA_FOR_MILKOMEDA_WHEN_EVM)
      : BigNumber(BRIDGE_ADA_FOR_MILKOMEDA_WHEN_CARDANO);
  });

  const isEnoughADAIntoMilkomeda = computed(() => {
    const balanceTokenIntoMilkomedaByMinDecimalsInToken = normalizeBalanceTokenIntoMilkomeda(
      milkomedaADAToken.value,
    );

    const inputADA = milkomedaWSCBridgeState.input.ADA
      ? milkomedaWSCBridgeState.input.ADA.amount
      : '0';
    const ADANeededIntoMilkomeda = amountMinADAIntoMilkomedaWhenCheckBridge.value.plus(inputADA);

    const isEnoughADA = balanceTokenIntoMilkomedaByMinDecimalsInToken.gte(ADANeededIntoMilkomeda);

    loggingIsEnoughADAIntoMilkomeda(
      isEnoughADA,
      balanceTokenIntoMilkomedaByMinDecimalsInToken,
      inputADA,
      amountMinADAIntoMilkomedaWhenCheckBridge.value,
      ADANeededIntoMilkomeda,
    );

    return isEnoughADA;
  });

  const amountADAWhichWillBridgeBySDK = computed(() => {
    const amountADA = BigNumber((milkomedaWSCBridgeState.bridge.length - 1) * 0.9);
    return max(amountADA, BIG_ZERO);
  });

  const $reset = (): void => {
    milkomedaWSCBridgeState.bridge = [];
    milkomedaWSCBridgeState.gas = null;
    milkomedaWSCBridgeState.needBridge = false;
    milkomedaWSCBridgeState.isValidCardanoBalance = false;
    milkomedaWSCBridgeState.minADABalance = null;
    milkomedaWSCBridgeState.input.tokens = [];
    milkomedaWSCBridgeState.input.ADA = null;

    isUnwrapADA.value = false;
  };

  async function validateADABalanceForCardano(): Promise<boolean> {
    LOGGER.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] validateADABalanceForCardano');
    if (!walletState.isInjected) return false;

    const balanceADAByMinDecimalsIntoCardano = normalizeBalanceTokenIntoCardano(
      cardanoADATokenForBridge.value,
    );
    LOGGER.log(
      'Balance ADA into Cardano [ ADA ] : ',
      balanceADAByMinDecimalsIntoCardano.toString(),
    );

    const amountADABySDK = amountADAWhichWillBridgeBySDK.value;
    LOGGER.log('ADA will be bridge by SDK [ ADA ] : ', amountADABySDK.toString());

    try {
      const minADABalanceIntoCardano = calculateMinADABalanceToCardano(
        TX_FEE_CARDANO_IN_ADA.multipliedBy(
          milkomedaWSCBridgeState.bridge.length ? milkomedaWSCBridgeState.bridge.length : BIG_ONE,
        ),
        BigNumber(milkomedaWSCBridgeState.gas?.amount ?? 0).plus(amountADABySDK),
        isEVM.value || !hasBridgeFromMilkomeda.value,
        isUnwrapADA.value,
      );
      const minADABalanceByMinDecimalsIntoCardano = normalizeAmountByMinDecimals(
        minADABalanceIntoCardano.toString(),
        milkomedaADAToken.value,
      );

      milkomedaWSCBridgeState.minADABalance = minADABalanceByMinDecimalsIntoCardano.toString();

      const isValid = minADABalanceByMinDecimalsIntoCardano.lte(balanceADAByMinDecimalsIntoCardano);
      LOGGER.log('is valid balance ADA into Cardano : ', isValid);
      return isValid;
    } catch (err) {
      console.error('[MILKOMEDA_WSC:BRIDGE] Happen error when validate ADA balance. ERROR : ', err);
      const minADABalanceIntoCardano = calculateMinADABalanceToCardano(
        TX_FEE_CARDANO_IN_ADA.multipliedBy(BIG_ONE),
        BigNumber(milkomedaWSCBridgeState.gas?.amount ?? 0),
        isEVM.value || !hasBridgeFromMilkomeda.value,
        isUnwrapADA.value,
      );
      const minADABalanceByMinDecimalsIntoCardano = normalizeAmountByMinDecimals(
        minADABalanceIntoCardano.toString(),
        milkomedaADAToken.value,
      );
      milkomedaWSCBridgeState.minADABalance = minADABalanceByMinDecimalsIntoCardano.toString();
      const isValid = minADABalanceByMinDecimalsIntoCardano.lte(balanceADAByMinDecimalsIntoCardano);
      LOGGER.log('is valid balance ADA into Cardano : ', isValid);
      return isValid;
    } finally {
      LOGGER.groupEnd();
    }
  }

  function normalizeBalanceTokenIntoMilkomeda(milkomedaToken: Token) {
    const balanceTokenIntoMilkomedaInToken =
      balanceByTokenSymbolAndChainId(
        milkomedaToken.symbol!,
        +DEFAULT_NETWORK_ID!,
      ).value?.balance.toExact() ?? '0';

    return normalizeAmountByMinDecimals(balanceTokenIntoMilkomedaInToken, milkomedaToken);
  }

  function normalizeBalanceTokenIntoCardano(cardanoToken: Token) {
    const milkomedaToken = getTokenBySymbolAndChainId(cardanoToken.symbol!, +DEFAULT_NETWORK_ID!);

    const balanceTokenIntoCardanoInToken =
      balanceByTokenSymbolAndChainId(
        cardanoToken.symbol!,
        +DEFAULT_CARDANO_CHAIN_ID!,
      ).value?.balance.toExact() ?? '0';

    return normalizeAmountByMinDecimals(balanceTokenIntoCardanoInToken, milkomedaToken);
  }

  async function normalizeEstimatedMaxBridgeAmountTokenFromCardano(cardanoToken: Token) {
    const milkomedaToken = getTokenBySymbolAndChainId(cardanoToken.symbol!, +DEFAULT_NETWORK_ID!);

    const estimatedMaxBridgeAmountInToken = await estimateMaxBridgeAmountFromCardano(cardanoToken);

    if (!estimatedMaxBridgeAmountInToken) return undefined;

    return normalizeAmountByMinDecimals(estimatedMaxBridgeAmountInToken.toString(), milkomedaToken);
  }

  function calculateAmountTokenNeededIntoMilkomeda(amount: string, token: Token | null) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return BIG_ZERO;
    if (!token) return BIG_ZERO;

    const balanceTokenIntoMilkomedaByMinDecimalsInToken = normalizeBalanceTokenIntoMilkomeda(token);

    return calculateAmountForBridge(
      BigNumber(amount ?? 0),
      balanceTokenIntoMilkomedaByMinDecimalsInToken,
      token,
    );
  }

  // CASES
  async function calculateAmountsForERC20AndCardano() {
    LOGGER.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] Case: ERC20 + Cardano.');
    milkomedaWSCBridgeState.needBridge =
      !isEnoughADAIntoMilkomeda.value || !!milkomedaWSCBridgeState.bridge.length;

    LOGGER.log('state (calc): ', _.cloneDeep(milkomedaWSCBridgeState));

    if (milkomedaWSCBridgeState.needBridge) {
      milkomedaWSCBridgeState.isValidCardanoBalance = await validateADABalanceForCardano();

      const amountADA = max(
        amountADAForBridge.value.minus(amountADAWhichWillBridgeBySDK.value),
        BIG_ZERO,
      );
      if (!amountADA.isZero() && amountADA.gte(MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA)) {
        milkomedaWSCBridgeState.gas = {
          token: cardanoADATokenForBridge.value,
          amount: amountADA.toString(),
        };
      }

      LOGGER.log('state (need bridge): ', milkomedaWSCBridgeState);
      LOGGER.groupEnd();
      return;
    }

    milkomedaWSCBridgeState.isValidCardanoBalance = true;
    milkomedaWSCBridgeState.minADABalance = null;
    milkomedaWSCBridgeState.gas = null;

    LOGGER.log('state (no bridge) : ', milkomedaWSCBridgeState);
    LOGGER.groupEnd();
  }

  async function calculateAmountsForADAAndCardano() {
    LOGGER.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] Case: ADA + Cardano.');
    milkomedaWSCBridgeState.needBridge =
      !isEnoughADAIntoMilkomeda.value || !!milkomedaWSCBridgeState.bridge.length;

    LOGGER.log('state (calc): ', _.cloneDeep(milkomedaWSCBridgeState));

    if (milkomedaWSCBridgeState.needBridge) {
      milkomedaWSCBridgeState.isValidCardanoBalance = await validateADABalanceForCardano();

      const amountADA = max(
        amountADAForBridge.value
          .plus(milkomedaWSCBridgeState.gas?.amount ?? 0)
          .minus(amountADAWhichWillBridgeBySDK.value),
        BIG_ZERO,
      );
      if (!amountADA.isZero() && amountADA.gte(MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA)) {
        milkomedaWSCBridgeState.gas = {
          token: cardanoADATokenForBridge.value!,
          amount: amountADA.toString(),
        };
      }

      LOGGER.log('state (need bridge): ', milkomedaWSCBridgeState);
      LOGGER.groupEnd();
      return;
    }

    milkomedaWSCBridgeState.isValidCardanoBalance = true;
    milkomedaWSCBridgeState.minADABalance = null;
    milkomedaWSCBridgeState.gas = null;

    LOGGER.log('state (no bridge) : ', milkomedaWSCBridgeState);
    LOGGER.groupEnd();
  }

  async function calculateAmountsForERC20AndEVM() {
    LOGGER.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] Case: ERC20 + EVM.');
    milkomedaWSCBridgeState.needBridge =
      !isEnoughADAIntoMilkomeda.value || !!milkomedaWSCBridgeState.bridge.length;

    LOGGER.log('state (calc): ', _.cloneDeep(milkomedaWSCBridgeState));

    if (milkomedaWSCBridgeState.needBridge) {
      milkomedaWSCBridgeState.isValidCardanoBalance = await validateADABalanceForCardano();

      const amountADA = max(
        amountADAForBridge.value.minus(amountADAWhichWillBridgeBySDK.value),
        BIG_ZERO,
      );
      if (!amountADA.isZero() && amountADA.gte(MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA)) {
        milkomedaWSCBridgeState.gas = {
          token: cardanoADATokenForBridge.value!,
          amount: amountADA.toString(),
        };
      }

      LOGGER.log('state (need bridge): ', milkomedaWSCBridgeState);
      LOGGER.groupEnd();
      return;
    }

    milkomedaWSCBridgeState.isValidCardanoBalance = true;
    milkomedaWSCBridgeState.minADABalance = null;
    milkomedaWSCBridgeState.gas = null;

    LOGGER.log('state (no bridge) : ', milkomedaWSCBridgeState);
    LOGGER.groupEnd();
  }

  async function calculateAmountsForADAAndEVM() {
    LOGGER.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] Case: ADA + EVM | Unwrap ADA.');
    milkomedaWSCBridgeState.needBridge =
      !isEnoughADAIntoMilkomeda.value || !!milkomedaWSCBridgeState.bridge.length;

    LOGGER.log('state (calc): ', _.cloneDeep(milkomedaWSCBridgeState));

    if (milkomedaWSCBridgeState.needBridge) {
      milkomedaWSCBridgeState.isValidCardanoBalance = await validateADABalanceForCardano();

      const amountADA = max(
        BigNumber(milkomedaWSCBridgeState.gas?.amount ?? 0)
          .plus(amountADAForBridge.value)
          .minus(isUnwrapADA.value ? SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA : BIG_ZERO)
          .minus(amountADAWhichWillBridgeBySDK.value),
        BIG_ZERO,
      );
      if (!amountADA.isZero() && amountADA.gte(MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA)) {
        milkomedaWSCBridgeState.gas = {
          token: cardanoADATokenForBridge.value!,
          amount: amountADA.toString(),
        };
      }

      LOGGER.log('state (need bridge): ', milkomedaWSCBridgeState);
      LOGGER.groupEnd();
      return;
    }

    milkomedaWSCBridgeState.isValidCardanoBalance = true;
    milkomedaWSCBridgeState.minADABalance = null;
    milkomedaWSCBridgeState.gas = null;

    LOGGER.log('state (no bridge) : ', milkomedaWSCBridgeState);
    LOGGER.groupEnd();
  }
  // ===

  async function calculateMaxADAIntoCardanoAndMilkomeda(totalFeesAndLookUps: BigNumber) {
    const balanceADAIntoCardanoByMinDecimals = normalizeBalanceTokenIntoCardano(
      cardanoADATokenForBridge.value,
    );
    const estimatedMaxBridgeAmountFromCardanoByMinDecimals =
      await normalizeEstimatedMaxBridgeAmountTokenFromCardano(cardanoADATokenForBridge.value);

    const balanceADAIntoMilkomedaByMinDecimals = normalizeBalanceTokenIntoMilkomeda(
      milkomedaADAToken.value,
    );

    return calculateMaxAmountADAIntoCardanoAndMilkomeda(
      balanceADAIntoCardanoByMinDecimals,
      estimatedMaxBridgeAmountFromCardanoByMinDecimals,
      balanceADAIntoMilkomedaByMinDecimals,
      totalFeesAndLookUps,
    );
  }

  async function calculateMaxTokenCardanoAndMilkomeda(milkomedaToken: Token) {
    const cardanoToken = getTokenBySymbolAndChainId(
      milkomedaToken.symbol!,
      +DEFAULT_CARDANO_CHAIN_ID,
    );
    const balanceTokenIntoCardanoByMinDecimals = normalizeBalanceTokenIntoCardano(cardanoToken);
    const estimatedMaxBridgeAmountFromCardanoByMinDecimals =
      await normalizeEstimatedMaxBridgeAmountTokenFromCardano(cardanoToken);

    const balanceTokenIntoMilkomedaByMinDecimals =
      normalizeBalanceTokenIntoMilkomeda(milkomedaToken);

    return min(
      estimatedMaxBridgeAmountFromCardanoByMinDecimals ?? BIG_ZERO,
      balanceTokenIntoCardanoByMinDecimals,
    ).plus(balanceTokenIntoMilkomedaByMinDecimals);
  }

  async function estimateMaxBridgeAmountFromCardano(token: Token): Promise<BigNumber | undefined> {
    if (!isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
      return undefined;
    }

    const cardanoToken = getTokenBySymbolAndChainId(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID);
    const cardanoWallet: CardanoWallet = getProviderBy(APP_NETWORK_NAME);

    if (!cardanoWallet) {
      return undefined;
    }

    const estimatedMaxBridgeAmountInWei = (
      await cardanoWallet.estimateMaxBridgeAmount(cardanoToken.address)
    ).amount;

    return fromWei(estimatedMaxBridgeAmountInWei, cardanoToken.decimals);
  }

  // Set tokens for bridge
  watch(
    () => input.value,
    input => {
      let hasTokenADA = false;
      milkomedaWSCBridgeState.gas = null;
      milkomedaWSCBridgeState.isValidCardanoBalance = false;
      milkomedaWSCBridgeState.minADABalance = null;

      const bridge: BridgeToken[] = [];
      input.forEach(({ amount, token }) => {
        const isGas = token.unwrapWETH().isETHToken();
        if (isGas) {
          hasTokenADA = true;
          milkomedaWSCBridgeState.input.ADA = { amount, token };
        } else {
          milkomedaWSCBridgeState.input.tokens.push({ amount, token });
        }

        const amountBridge = calculateAmountTokenNeededIntoMilkomeda(amount, token);

        if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
          const cardanoToken = getTokenBySymbolAndChainId(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID);
          if (!amountBridge.isZero() && !isGas) {
            bridge.push({
              amount: amountBridge.toString(),
              token: cardanoToken,
            });
          } else if (!amountBridge.isZero() && isGas) {
            milkomedaWSCBridgeState.gas = {
              amount: amountBridge.toString(),
              token: cardanoToken,
            };
          }
        }
      });
      milkomedaWSCBridgeState.bridge = bridge;
      hasADA.value = hasTokenADA;
      LOGGER.log(
        '[MILKOMEDA_WSC:BRIDGE] state after changed input tokens : ',
        _.cloneDeep(milkomedaWSCBridgeState),
      );
    },
  );

  // Calculate for cases
  watch(
    [
      () => isEVM.value,
      () => hasBridgeFromMilkomeda.value,
      () => hasADA.value,
      () => milkomedaWSCBridgeState.bridge,
    ],
    async ([isEVM, hasBridgeFromMilkomeda, hasADA]) => {
      milkomedaWSCBridgeState.isValidCardanoBalance = false;
      milkomedaWSCBridgeState.minADABalance = null;

      if (
        !input.value.length ||
        (!milkomedaWSCBridgeState.input.tokens.length && !milkomedaWSCBridgeState.input.ADA)
      )
        return;

      // Into EVM
      // Then ADA (Milkomeda) unwrap to ADA (Cardano).
      if (isEVM || !hasBridgeFromMilkomeda) {
        await (hasADA ? calculateAmountsForADAAndEVM : calculateAmountsForERC20AndEVM)();
        return;
      }
      // Into Cardano
      await (hasADA ? calculateAmountsForADAAndCardano : calculateAmountsForERC20AndCardano)();
    },
  );

  return {
    milkomedaWSCBridgeState,
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    setIsUnwrapADAFromMilkomeda,
    $reset,
    calculateMaxADAIntoCardanoAndMilkomeda,
    calculateMaxTokenCardanoAndMilkomeda,
    estimateMaxBridgeAmountFromCardano,
  };
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].WSC;
}

function loggingIsEnoughADAIntoMilkomeda(
  isEnoughADA: boolean,
  balanceTokenIntoMilkomedaByMinDecimalsInToken: BigNumber,
  inputADA: string,
  amountMinADAIntoMilkomedaWhenCheckBridge: BigNumber,
  ADANeededIntoMilkomeda: BigNumber,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] is enough ADA into Milkomeda : ', isEnoughADA);
  console.log(
    'Balance ADA into Milkomeda [ ADA ] : ',
    balanceTokenIntoMilkomedaByMinDecimalsInToken.toString(),
  );
  console.log('input ADA [ ADA ] : ', inputADA.toString());
  console.log(
    'amount ADA into Milkomeda for check wrap [ ADA ] : ',
    amountMinADAIntoMilkomedaWhenCheckBridge.toString(),
  );
  console.log('ADA needed into Milkomeda [ ADA ] : ', ADANeededIntoMilkomeda.toString());
  console.groupEnd();
}
