import { computed, reactive, ref, watch } from 'vue';
import BigNumber from 'bignumber.js';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { Token } from '@/sdk/entities/token';
import { IMilkomedaWSCUnwrapBridgeState } from '@/composables/milkomeda-wrapped-smartcontract/models/milkomeda-wsc-unwrap-bridge-state.interface';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export function useMilkomedaWSCUnwrapBridgeCalculations() {
  const { getTokenBySymbolAndChainId } = useTokens();

  const input = ref<BridgeToken[]>([]);
  const isEVM = ref(false);
  const hasADA = ref(false);

  const milkomedaWSCUnwrapBridgeState = reactive<IMilkomedaWSCUnwrapBridgeState>({
    input: {
      tokens: [],
      ADA: null,
    },
    gas: null,
    bridge: [],
    needRevertBridge: false,
  });

  function setBridgeTokensFromMilkomeda(tokens: BridgeToken[]): void {
    milkomedaWSCUnwrapBridgeState.input.tokens = [];
    milkomedaWSCUnwrapBridgeState.input.ADA = null;
    input.value = tokens;
  }

  function setIsEVMFromMilkomeda(willToEVM: boolean): void {
    isEVM.value = willToEVM;
    milkomedaWSCUnwrapBridgeState.needRevertBridge = !willToEVM;
    if (willToEVM) {
      $reset();
    }
  }

  const milkomedaADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_NETWORK_ID!);
  });

  function $reset(): void {
    milkomedaWSCUnwrapBridgeState.bridge = [];
    milkomedaWSCUnwrapBridgeState.gas = null;
    milkomedaWSCUnwrapBridgeState.needRevertBridge = false;
    milkomedaWSCUnwrapBridgeState.input.tokens = [];
    milkomedaWSCUnwrapBridgeState.input.ADA = null;
    input.value = [];
  }

  function calculateAmounts() {
    LOGGER.log('[MILKOMEDA_WSC:UNWRAP_BRIDGE] Case: bridge from Milkomeda');
    milkomedaWSCUnwrapBridgeState.gas = {
      token: milkomedaADATokenForBridge.value,
      amount: BigNumber(milkomedaWSCUnwrapBridgeState.gas?.amount ?? 0)
        .plus(MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA)
        .toString(),
    };
  }

  watch(
    () => input.value,
    input => {
      let hasTokenADA = false;
      milkomedaWSCUnwrapBridgeState.gas = null;

      if (isEVM.value) return;

      const bridge: BridgeToken[] = [];
      input.forEach(({ amount, token }) => {
        const isGas = token.unwrapWETH().isETHToken();
        if (isGas) {
          hasTokenADA = true;
          milkomedaWSCUnwrapBridgeState.input.ADA = { amount, token };
        } else {
          milkomedaWSCUnwrapBridgeState.input.tokens.push({ amount, token });
        }

        const bridgeAmount = BigNumber(amount);

        if (!bridgeAmount.isZero() && !isGas) {
          bridge.push({
            amount: amount,
            token,
          });
        } else if (!bridgeAmount.isZero() && isGas) {
          milkomedaWSCUnwrapBridgeState.gas = {
            amount: amount,
            token,
          };
        }
      });
      milkomedaWSCUnwrapBridgeState.bridge = bridge;
      hasADA.value = hasTokenADA;
    },
  );

  watch([() => isEVM.value, () => milkomedaWSCUnwrapBridgeState.bridge], async ([isEVM]) => {
    if (isEVM) return;

    if (!input.value.length) return;

    calculateAmounts();
  });

  return {
    milkomedaWSCUnwrapBridgeState,
    setIsEVMFromMilkomeda,
    setBridgeTokensFromMilkomeda,
    $reset,
  };
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].WSC;
}
