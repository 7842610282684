import { BigNumber } from 'bignumber.js';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { getErc20Contract } from './contract.helper';
import { ethersToBigNumber } from '@/utils/bigNumber';
import { Token } from '@/sdk/entities/token';
import { MULTICHAIN_TOKEN_PREFIX } from '@/constants/MULTICHAIN_TOKEN_PREFIX';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export async function checkERC20Allowance(
  token: Token,
  owner: string,
  spender: string,
): Promise<BigNumber> {
  LOGGER.groupCollapsed(`[ALLOWANCE] Token [ ${token.symbol} | ${token.address}]`);
  const tokenContract = getErc20Contract(token.address, getInstance()?.web3?.getSigner());
  LOGGER.log('tokenContract : ', tokenContract);
  LOGGER.log('owner : ', owner);
  LOGGER.log('spender : ', spender);

  const allowance = await tokenContract.allowance(owner, spender);
  const allowanceInWei = ethersToBigNumber(allowance);
  LOGGER.log(`allowance (${token.symbol}) [ WEI ]: `, allowanceInWei.toString());
  LOGGER.log(
    `allowance (${token.symbol}) [ TOKEN ]: `,
    allowanceInWei.shiftedBy(-token.decimals).toString(),
  );

  LOGGER.groupEnd();
  return allowanceInWei;
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].ALLOWANCE_TOKEN;
}

export function isMultichainToken(tokenSymbol: string) {
  return tokenSymbol ? tokenSymbol.toLowerCase().startsWith(MULTICHAIN_TOKEN_PREFIX) : false;
}
