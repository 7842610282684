import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import {
  getLiquidityDelegateContract,
  getMultiCallContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { getLiquidityDelegateAddress } from '@/helpers/address.helper';
import { applySlippageInPercents } from '@/sdk/utils';
import { dateNowInSeconds, safeDateNowPlusEstimatedMinutes } from '@/helpers/utils';
import { Token } from '@/sdk/entities/token';
import { useSingleSide } from '@/store/modules/single-side/useSingleSide';
import { EIP712Domain, useWalletSignature } from '../useWalletSignature';
import { ethersToBigNumber } from '@/utils/bigNumber';

type DelegatedWithdrawParams = {
  amount: string; // LP token in wei from farm
  userAddress: string;
  portfolio: string; // portfolio address
  farm: string; // farm address
  timestamp: number; // when created signature. expired after 60 sec. value should be in seconds.
};

export type DelegatedWithdrawParamsWithSignature = DelegatedWithdrawParams & {
  signature: ethers.Signature;
};

type DelegateWithdrawMethodCallArgs = {
  tokens: string[]; // token addresses
  lptAmounts: string[];
  minAmountsOut: string[]; // based on estimated withdraw
  to: string;
  deadline: number;
  delegatedWithdrawParams: DelegatedWithdrawParamsWithSignature;
};

export const DelegatedWithdrawParams = [
  { name: 'amount', type: 'uint256' },
  { name: 'userAddress', type: 'address' },
  { name: 'portfolio', type: 'address' },
  { name: 'farm', type: 'address' },
  { name: 'timestamp', type: 'uint256' },
];

const MINUTE_IN_SEC = 60;

export function useSingleSideWithdrawTransaction() {
  const { walletState } = useEVMWallet();
  const singleSideStore = useSingleSide();
  const { createSignature } = useWalletSignature();

  async function getDelegatedWithdrawParams({
    lpAmountFromFarmInWei,
    portfolioAddress,
    farmAddress,
  }: {
    lpAmountFromFarmInWei: string;
    portfolioAddress: string;
    farmAddress: string;
  }): Promise<DelegatedWithdrawParams> {
    const multi = getMultiCallContract();
    const currentBlockTimestamp = ethersToBigNumber(await multi.getCurrentBlockTimestamp());
    const userTimestamp = dateNowInSeconds();
    const diff = currentBlockTimestamp.minus(userTimestamp).abs();
    const timestamp = diff.lt(MINUTE_IN_SEC) ? currentBlockTimestamp.toNumber() : userTimestamp;

    console.groupCollapsed('[LIQUIDITY DELEGATE] Signature timestamp : ', timestamp);
    console.log('current block timestamp : ', currentBlockTimestamp.toNumber());
    console.log('user timestamp : ', userTimestamp);
    console.log('diff : ', diff.toString());
    console.groupEnd();

    return {
      amount: lpAmountFromFarmInWei,
      userAddress: walletState.account ?? '',
      portfolio: portfolioAddress,
      farm: farmAddress,
      timestamp,
    };
  }

  /**
   * Create string of typed data to sign.
   *
   * @param delegatedWithdrawParams data which will sign
   * @returns message for sign
   */
  function convertDelegateWithdrawParamsToTypedData(
    delegatedWithdrawParams: DelegatedWithdrawParams,
  ): string {
    return JSON.stringify({
      primaryType: 'DelegatedWithdrawParams',
      types: { EIP712Domain, DelegatedWithdrawParams },
      /**
       * Metadata about the verifying contract
       * to provide replay protection of this signature
       * between different contract instances.
       */
      domain: {
        name: 'Farming',
        version: 'V4',
        chainId: walletState.network?.chainId.toString(),
        verifyingContract: delegatedWithdrawParams.farm,
      },
      // This defines the message we are proposing the user to sign.
      message: delegatedWithdrawParams,
    });
  }

  async function signDelegateWithdrawParams(
    delegatedWithdrawParams: DelegatedWithdrawParams,
  ): Promise<DelegatedWithdrawParamsWithSignature> {
    const typedDataForSignature = convertDelegateWithdrawParamsToTypedData(delegatedWithdrawParams);

    const signature = await createSignature(walletState.account ?? '', typedDataForSignature);

    return {
      ...delegatedWithdrawParams,
      signature,
    };
  }

  function getDelegateWithdrawMethodCallArgs({
    withdrawTokens,
    delegatedWithdrawParamsWithSignature,
  }: {
    withdrawTokens: {
      token: Pick<Token, 'address' | 'decimals'>;
      lpInWeiAmount: BigNumber;
      amountOutInWei: BigNumber;
    }[];
    delegatedWithdrawParamsWithSignature: DelegatedWithdrawParamsWithSignature;
  }): DelegateWithdrawMethodCallArgs {
    const tokens: string[] = [];
    const lptAmounts: string[] = [];
    const minAmountsOut: string[] = [];

    withdrawTokens.forEach(({ token, lpInWeiAmount, amountOutInWei }) => {
      tokens.push(token.address);
      lptAmounts.push(lpInWeiAmount.toFixed(0, BigNumber.ROUND_DOWN));

      const minAmountOut = applySlippageInPercents(
        amountOutInWei,
        singleSideStore.settings.slippageTolerance,
      ).toFixed(0, BigNumber.ROUND_DOWN);
      minAmountsOut.push(minAmountOut);
    });

    return {
      tokens,
      lptAmounts,
      minAmountsOut,
      to: walletState.account ?? '',
      deadline: safeDateNowPlusEstimatedMinutes(+singleSideStore.settings.transactionDeadline),
      delegatedWithdrawParams: delegatedWithdrawParamsWithSignature,
    };
  }

  async function createDelegateWithdrawTransaction(
    callArgs: DelegateWithdrawMethodCallArgs,
  ): Promise<ethers.providers.TransactionResponse> {
    const liquidityDelegateContract: ethers.Contract = getLiquidityDelegateContract(
      getLiquidityDelegateAddress(),
      getInstance().web3.getSigner(),
    );

    const multi = getMultiCallContract();
    const currentBlockTimestamp = ethersToBigNumber(await multi.getCurrentBlockTimestamp());
    const diff = currentBlockTimestamp.minus(callArgs.delegatedWithdrawParams.timestamp).abs();
    const hasTimestampWarn = diff.gte(MINUTE_IN_SEC);

    if (hasTimestampWarn) {
      console.warn(
        '[LIQUIDITY DELEGATE] : SIGNATURE CAN BE EXPIRED ON CHAIN.',
        ' DIFF : ',
        diff.toString(),
      );
    }
    console.groupCollapsed(
      '[LIQUIDITY DELEGATE] delegateWithdraw : ',
      liquidityDelegateContract.address,
      `${hasTimestampWarn ? '[SIGNATURE TIMESTAMP WARN]' : ''}`,
    );
    console.table(callArgs);
    console.log('current block timestamp : ', currentBlockTimestamp.toString());
    console.groupEnd();

    const transactionResponse = await transactionWithEstimatedGas(
      liquidityDelegateContract,
      'delegateWithdraw',
      Object.values(callArgs),
    );

    return transactionResponse;
  }

  return {
    getDelegatedWithdrawParams,
    signDelegateWithdrawParams,
    getDelegateWithdrawMethodCallArgs,
    createDelegateWithdrawTransaction,
  };
}
