import { ethers } from 'ethers';
import { computed } from 'vue';
import { min } from '@/utils/bigNumber';
import { BridgeResponse } from 'crypto-sdk';
import {
  APP_NETWORK_NAME,
  DEFAULT_NETWORK_ID,
  SELECTED_NETWORK_NAME,
} from '@/helpers/networkParams.helper';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { getScanLink } from '@/sdk/utils';
import {
  approveForBridgeFromMilkomedaToCardano,
  bridgeFromMilkomedaToCardano,
} from '@/helpers/bridge-methods';
import { Token } from '@/sdk/entities/token';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import CryptoSDKConnector from '@/helpers/connectors/snapshot-labs/crypto-sdk';
import { BridgeToken } from './models/bridge-token';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useMilkomedaWSCUnwrapBridgeCalculations } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCUnwrapBridgeCalculations';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

export function useMilkomedaWSCUnwrapBridge() {
  const { getTokenBySymbolAndChainId } = useTokens();
  const { updateTokenBalances, balanceByTokenSymbolAndChainId } = useBalances();
  const { walletState } = useWallet();
  const {
    milkomedaWSCUnwrapBridgeState: unwrapBridgeState,
    setBridgeTokensFromMilkomeda: setBridgeTokens,
    setIsEVMFromMilkomeda: setIsEVM,
    $reset: resetUnwrapBridge,
  } = useMilkomedaWSCUnwrapBridgeCalculations();

  const walletName = computed(() => {
    return walletState.wallets[SELECTED_NETWORK_NAME].connector.name;
  });

  const milkomedaWSCUnwrapBridgeState = unwrapBridgeState;

  function setBridgeTokensFromMilkomeda(tokens: BridgeToken[]): void {
    setBridgeTokens(tokens);
  }

  function setIsEVMFromMilkomeda(willToEVM: boolean): void {
    setIsEVM(willToEVM);
  }

  const milkomedaADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_NETWORK_ID!);
  });

  const { addNotification } = useNotifications();

  const getApproveForBridgeNotificationOptions = (
    options: {
      status: NotificationStatus;
      id: string;
      explorerLink?: string;
    },
    token?: string | null,
  ): INotification => {
    const approveItem = token ? token : 'tokens';
    const notificationContent = {
      inProgress: `Approving ${approveItem}`,
      success: `Approving ${approveItem}`,
      error: `Approving ${approveItem} was failed. Please, send your submission again.`,
    };
    return {
      ...options,
      content: notificationContent[options.status],
    };
  };

  const getBridgeFromMilkomedaNotificationOptions = (options: {
    status: NotificationStatus;
    id: string;
    explorerLink?: string;
  }): INotification => {
    const wallet = walletName.value;
    const notificationContent = {
      inProgress: [`Sending tokens to ${wallet} wallet`, 'Estimated time of arrival 5-15 min.'],
      success: `Sending tokens to ${wallet} wallet`,
      error: `Sending tokens to ${wallet} wallet was failed. Please, send your submission again.`,
    };
    return {
      ...options,
      content: notificationContent[options.status],
    };
  };

  function $reset(): void {
    resetUnwrapBridge();
  }

  // PROCESSING APPROVE FOR UNWRAP BRIDGE
  async function doApproveForBridgeFromMilkomeda(isSingleToken = false) {
    console.groupCollapsed('[MILKOMEDA_WSC:UNWRAP_BRIDGE] doApproveForBridge');

    console.log('state for bridge from milkomeda : ', milkomedaWSCUnwrapBridgeState);

    const notificationId = 'approve_bridge_token';

    try {
      addNotification(
        getApproveForBridgeNotificationOptions(
          {
            id: notificationId,
            status: 'inProgress',
          },
          isSingleToken ? milkomedaWSCUnwrapBridgeState.bridge[0].token.symbol : null,
        ),
      );

      const approveResponses: Array<ethers.providers.TransactionResponse | undefined> = [];
      for (let i = 0; i < milkomedaWSCUnwrapBridgeState.bridge.length; i++) {
        const bridgeToken = milkomedaWSCUnwrapBridgeState.bridge[i];
        approveResponses.push(await doApproveTokenForBridgeFromMilkomeda(bridgeToken));
      }

      let explorerLink: string | undefined;
      // NOTE: Check that approve one token
      if (approveResponses.length === 1 && approveResponses[0]) {
        explorerLink = getScanLink(approveResponses[0].hash, 'transaction', +DEFAULT_NETWORK_ID!);
      }

      addNotification(
        getApproveForBridgeNotificationOptions(
          {
            id: notificationId,
            status: 'success',
            explorerLink,
          },
          isSingleToken ? milkomedaWSCUnwrapBridgeState.bridge[0].token.symbol : null,
        ),
      );
    } catch (error) {
      addNotification(
        getApproveForBridgeNotificationOptions(
          {
            id: notificationId,
            status: 'error',
          },
          isSingleToken ? milkomedaWSCUnwrapBridgeState.bridge[0].token.symbol : null,
        ),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  async function doApproveTokenForBridgeFromMilkomeda(
    bridgeToken: BridgeToken,
  ): Promise<ethers.providers.TransactionResponse | undefined> {
    const cryptoSDKConnector: CryptoSDKConnector =
      getInstance().provider.value['cryptoSdkConnector'];
    const cardanoWalletAddress = walletState.wallets[APP_NETWORK_NAME].account;
    const milkomedaWalletAddress = walletState.wallets[SELECTED_NETWORK_NAME].account;

    if (!cryptoSDKConnector) {
      console.error('[MILKOMEDA_WSC:UNWRAP_BRIDGE:APPROVE] Can not get crypto-sdk connector.');
      throw Error('Can not get crypto-sdk connector for bridge.');
    }

    try {
      // APPROVE
      const approve = await approveForBridgeFromMilkomedaToCardano(
        bridgeToken,
        cryptoSDKConnector.milkomedaBridgeProvider!,
        cardanoWalletAddress,
        milkomedaWalletAddress,
      );
      console.log('approve response : ', approve);

      return approve;
    } catch (error) {
      console.error('[MILKOMEDA_WSC:UNWRAP_BRIDGE:APPROVE] ERROR : ', error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }
      throw error;
    }
  }
  // ===

  // PROCESSING UNWRAP BRIDGE
  async function doBridgeFromMilkomeda() {
    console.groupCollapsed('[MILKOMEDA_WSC:UNWRAP_BRIDGE] doBridgeFromMilkomeda');

    console.log('state for bridge from milkomeda : ', milkomedaWSCUnwrapBridgeState);

    const notificationId = 'bridge_token_from_milkomeda';

    try {
      addNotification(
        getBridgeFromMilkomedaNotificationOptions({
          id: notificationId,
          status: 'inProgress',
        }),
      );

      const minGas: BridgeToken = {
        amount: MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA.toString(),
        token: milkomedaADATokenForBridge.value,
      };

      let bridgeResponse: BridgeResponse | undefined;
      if (milkomedaWSCUnwrapBridgeState.bridge.length) {
        // NOTE:
        // Check balance of token.
        // If balance is less than amount that we want unwrap, then amount will be balance.
        await updateTokenBalances();
        LOGGER.groupCollapsed('Fix amount token by balance');
        const bridgeTokens = milkomedaWSCUnwrapBridgeState.bridge.map<BridgeToken>(
          ({ amount, token }: BridgeToken) => {
            const balance =
              balanceByTokenSymbolAndChainId(
                token.symbol!,
                +DEFAULT_NETWORK_ID!,
              ).value?.balance.toFixed() ?? '0';

            const bridgeToken = {
              token,
              amount: min(balance, amount).toString(),
            };

            LOGGER.log(
              `[ ${token.symbol} ] min( amount: ${amount} | balance: ${balance} ) : ${bridgeToken.amount}`,
            );

            return bridgeToken;
          },
        );
        LOGGER.groupEnd();

        const bridgeResponses = await Promise.all(
          bridgeTokens.map((bridgeToken, index) =>
            doBridgeTokenFromMilkomeda(
              index === 0 ? milkomedaWSCUnwrapBridgeState.gas! : minGas,
              bridgeToken,
            ),
          ),
        );

        // NOTE: Check that unwrap one token
        if (bridgeResponses.length === 1) {
          bridgeResponse = bridgeResponses[0];
        }
      } else {
        bridgeResponse = await doBridgeTokenFromMilkomeda(milkomedaWSCUnwrapBridgeState.gas!);
      }

      const explorerLink = await getExplorerLink(bridgeResponse);

      addNotification(
        getBridgeFromMilkomedaNotificationOptions({
          id: notificationId,
          status: 'success',
          explorerLink,
        }),
      );
    } catch (error) {
      addNotification(
        getBridgeFromMilkomedaNotificationOptions({
          id: notificationId,
          status: 'error',
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  async function doBridgeTokenFromMilkomeda(
    gas: BridgeToken,
    bridgeToken?: BridgeToken,
  ): Promise<BridgeResponse | undefined> {
    const cryptoSDKConnector: CryptoSDKConnector =
      getInstance().provider.value['cryptoSdkConnector'];
    const cardanoWalletAddress = walletState.wallets[APP_NETWORK_NAME].account;
    const milkomedaWalletAddress = walletState.wallets[SELECTED_NETWORK_NAME].account;

    if (!cryptoSDKConnector) {
      console.error('[MILKOMEDA_WSC:UNWRAP_BRIDGE] Can not get crypto-sdk connector.');
      throw Error('Can not get crypto-sdk connector for bridge.');
    }

    try {
      // BRIDGE
      const bridgeSwap = await bridgeFromMilkomedaToCardano(
        gas!,
        bridgeToken ? bridgeToken : null,
        cryptoSDKConnector.milkomedaBridgeProvider!,
        cardanoWalletAddress,
        milkomedaWalletAddress,
      );
      console.log('bridge response : ', bridgeSwap);

      // PROCESSING TX FROM
      const fromTx = bridgeSwap.from.tx;
      if (!fromTx) {
        console.warn(`[MILKOMEDA_WSC:UNWRAP_BRIDGE] 'from' transaction is undefined.`);
        return;
      }
      console.log(`waiting block for 'from' : `, fromTx);
      const fromBlock = await fromTx.wait();
      console.log(`has block for 'from' : `, fromBlock);

      // PROCESSING TX TO
      console.log(`waiting 'to' transaction`);
      const toTx = await bridgeSwap.to.tx;
      console.log(`has 'to' transaction : `, toTx);
      if (!toTx) {
        console.warn(`[MILKOMEDA_WSC:UNWRAP_BRIDGE] 'to' transaction is undefined.`);
        return;
      }
      console.log(
        `waiting block for 'to' : `,
        toTx,
        ' | blockfrost : ',
        cryptoSDKConnector.blockfrost,
      );
      const toBlock = await toTx.wait(cryptoSDKConnector.blockfrost);
      console.log(`has block for 'to' : `, toBlock);

      return bridgeSwap;
    } catch (error) {
      console.error('[MILKOMEDA_WSC:UNWRAP_BRIDGE] ERROR : ', error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }
      throw error;
    }
  }
  // ===

  return {
    milkomedaWSCUnwrapBridgeState,
    setIsEVMFromMilkomeda,
    setBridgeTokensFromMilkomeda,
    doApproveForBridgeFromMilkomeda,
    doBridgeFromMilkomeda,
    $reset,
  };
}

async function getExplorerLink(
  bridgeResponse: BridgeResponse | undefined,
): Promise<string | undefined> {
  let explorerLink: string | undefined;

  if (bridgeResponse) {
    const hashFrom = bridgeResponse.from.tx?.hash;
    const hashTo = (await bridgeResponse.to.tx)?.hash;
    if (hashTo) {
      explorerLink = getScanLink(hashTo, 'transaction', +DEFAULT_CARDANO_CHAIN_ID);
    } else if (hashFrom) {
      explorerLink = getScanLink(hashFrom, 'transaction', +DEFAULT_NETWORK_ID!);
    }
  }

  return explorerLink;
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].WSC;
}
