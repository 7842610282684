import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { STEP_STATUS, STEP_TYPE, StepInfo } from '@/components/stepper/stepper.types';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useFarmingMilkomedaWSCBridge } from '@/store/modules/farming/useFarmingMilkomedaWSCBridge';
import { useFarmingMilkomedaWSCUnwrapBridge } from '@/store/modules/farming/useFarmingMilkomedaWSCUnwrapBridge';
import { useFarmingUnstake } from './useFarmingUnstake';

interface ISingleSideWithdrawStepsState {
  steps: StepInfo[];
  currentStep: StepInfo | null;
  isShown: boolean;
}

export const useFarmingUnstakeSteps = defineStore('farmingUnstakeSteps', () => {
  const { t } = useI18n();
  const { walletState } = useWallet();
  const { milkomedaWSCBridgeState, doBridgeFromCardano } = useFarmingMilkomedaWSCBridge();
  const { milkomedaWSCUnwrapBridgeState, doApproveForBridgeFromMilkomeda, doBridgeFromMilkomeda } =
    useFarmingMilkomedaWSCUnwrapBridge();
  const { doUnstake } = useFarmingUnstake();

  const walletName = computed(() => {
    return walletState.wallets[SELECTED_NETWORK_NAME].connector.name;
  });

  const doStepAction = {
    [STEP_TYPE.BRIDGE]: async () => {
      await doBridgeFromCardano();
    },
    [STEP_TYPE.SIGN]: async () => {
      await doUnstake();
    },
    [STEP_TYPE.REVERSE_APPROVE]: async () => {
      await doApproveForBridgeFromMilkomeda();
    },
    [STEP_TYPE.REVERSE_BRIDGE]: async () => {
      await doBridgeFromMilkomeda();
    },
  };

  let nextStep = {};

  const farmingUnstakeSteps = reactive<ISingleSideWithdrawStepsState>({
    steps: [],
    currentStep: null,
    isShown: false,
  });

  function $reset() {
    nextStep = {};
    farmingUnstakeSteps.steps = [];
    farmingUnstakeSteps.currentStep = null;
    farmingUnstakeSteps.isShown = false;
  }

  // BRIDGE
  function addBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCBridgeState.needBridge) {
      const step = {
        name: t('farming.steps.bridge'),
        type: STEP_TYPE.BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      farmingUnstakeSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      return {
        prevStep: STEP_TYPE.BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // SIGN
  function addSignStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    const step = {
      name: t('farming.steps.sign.unstake'),
      type: STEP_TYPE.SIGN,
      status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
    };
    farmingUnstakeSteps.steps.push(step);
    // set current step
    if (!currentStep) {
      currentStep = step;
    }
    // next step
    if (prevStep) {
      nextStep[prevStep] = step;
    }

    return {
      prevStep: STEP_TYPE.SIGN,
      currentStep,
    };
  }

  // REVERSE APPROVE
  function addReverseApproveStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (
      milkomedaWSCUnwrapBridgeState.needRevertBridge &&
      milkomedaWSCUnwrapBridgeState.bridge.length
    ) {
      const step = {
        name: t('farming.steps.reverseApprove'),
        type: STEP_TYPE.REVERSE_APPROVE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      farmingUnstakeSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.REVERSE_APPROVE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // REVERSE BRIDGE
  function addReverseBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCUnwrapBridgeState.needRevertBridge) {
      const step = {
        name: t('farming.steps.reverseBridge', { wallet: walletName.value }),
        type: STEP_TYPE.REVERSE_BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      farmingUnstakeSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.REVERSE_BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  function prepareSteps(): void {
    let step: StepInfo | null = null;
    let prevStep: STEP_TYPE | null = null;

    let state = addBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addSignStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addReverseApproveStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addReverseBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    // next step
    if (prevStep) {
      nextStep[prevStep] = null;
    }

    farmingUnstakeSteps.currentStep = step;
  }

  async function doStep(step: StepInfo) {
    step.status = STEP_STATUS.PROGRESS;
    await doStepAction[step.type]();
    step.status = STEP_STATUS.SUCCESS;
    farmingUnstakeSteps.currentStep = nextStep[step.type];
  }

  async function runSteps() {
    if (!farmingUnstakeSteps.currentStep) return;

    try {
      while (farmingUnstakeSteps.currentStep) {
        await doStep(farmingUnstakeSteps.currentStep);
      }
    } catch (error) {
      farmingUnstakeSteps.currentStep.status = STEP_STATUS.ERROR;
      throw Error(error);
    }
  }

  return {
    farmingUnstakeSteps,
    prepareSteps,
    runSteps,
    $reset,
  };
});
