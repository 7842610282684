import _ from 'lodash';
import { ref, watch } from 'vue';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import {
  APP_NETWORK_NAME,
  DEFAULT_NETWORK_ID,
  SELECTED_NETWORK_NAME,
} from '@/helpers/networkParams.helper';
import { getScanLink } from '@/sdk/utils';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { BridgeResponse, CardanoWallet } from 'crypto-sdk';
import CryptoSDKConnector from '@/helpers/connectors/snapshot-labs/crypto-sdk';
import { TokenBalance, useBalances } from '@/store/modules/tokens/useBalances';
import { bridgeFromCardanoToMilkomeda } from '@/helpers/bridge-methods';
import { useMilkomedaWSCBridgeCalculations } from '@/composables/milkomeda-wrapped-smartcontract/useMilkomedaWSCBridgeCalculations';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { BridgeToken } from './models/bridge-token';
import { IMilkomedaWSCBridgeState } from './models/milkomeda-wsc-bridge-state.interface';

type Bridge = {
  gas: BridgeToken | undefined;
  token: BridgeToken | undefined;
  input: {
    gas: BridgeToken | undefined;
    token: BridgeToken | undefined;
  };
};

export function useMilkomedaWSCBridge() {
  const { balanceByTokenSymbolAndChainId } = useBalances();
  const { walletState, getProviderBy } = useWallet();
  const {
    milkomedaWSCBridgeState: bridgeState,
    setBridgeTokensFromCardano: setBridgeTokens,
    setIsEVMFromCardano: setIsEVM,
    setHasBridgeFromMilkomeda: setHasBridge,
    setIsUnwrapADAFromMilkomeda: setIsUnwrapADA,
    $reset: resetMilkomedaWSCBridge,
    calculateMaxADAIntoCardanoAndMilkomeda,
    estimateMaxBridgeAmountFromCardano,
  } = useMilkomedaWSCBridgeCalculations();

  const milkomedaWSCBridgeState = bridgeState;

  const destinationWalletAddress = ref<string | null>(null);

  function setDestinationAddress(walletAddress: string | null): void {
    destinationWalletAddress.value = walletAddress;
  }

  function setBridgeTokensFromCardano(tokens: BridgeToken[]): void {
    setBridgeTokens(tokens);
  }

  function setIsEVMFromCardano(willToEVM: boolean): void {
    setIsEVM(willToEVM);
  }

  function setHasBridgeFromMilkomeda(willBridgeFromMilkomeda: boolean): void {
    setHasBridge(willBridgeFromMilkomeda);
  }

  function setIsUnwrapADAFromMilkomeda(isUnwrapADAFromMilkomeda: boolean): void {
    setIsUnwrapADA(isUnwrapADAFromMilkomeda);
  }

  const { addNotification } = useNotifications();

  const getBridgeFromCardanoNotificationOptions = (options: {
    status: NotificationStatus;
    id: string;
    explorerLink?: string;
  }): INotification => {
    const notificationContent = {
      inProgress: [
        'Sending tokens from Cardano to Milkomeda',
        'Estimated time of arrival 5-15 min.',
      ],
      success: 'Sending tokens from Cardano to Milkomeda',
      error:
        'Sending tokens from Cardano to Milkomeda was failed. Please, send your submission again.',
    };
    return {
      ...options,
      content: notificationContent[options.status],
    };
  };

  const $reset = (): void => {
    resetMilkomedaWSCBridge();
  };

  // PROCESSING BRIDGE
  async function processingCheckBalanceIntoMilkomeda(
    bridge: Bridge,
    abortControllerCheckBalance: AbortController,
  ): Promise<Bridge> {
    return new Promise((resolve, reject) => {
      const stopCheckBalance = watch(
        [
          () =>
            bridge.gas
              ? balanceByTokenSymbolAndChainId(bridge.gas.token.symbol!, +DEFAULT_NETWORK_ID!).value
              : null,
          () =>
            bridge.token
              ? balanceByTokenSymbolAndChainId(bridge.token.token.symbol!, +DEFAULT_NETWORK_ID!)
                  .value
              : null,
        ],
        ([gasBalance, tokenBalance]) => {
          console.log('[MILKOMEDA_WSC:BRIDGE] Check balance into Milkomeda.');
          loggingProcessingCheckBalanceIntoMilkomeda(bridge, gasBalance, tokenBalance);
          // GAS
          const currentGasBalanceIntoMilkomeda = +(gasBalance?.balance.toFixed() ?? 0);
          const waitingGasAmount = +(bridge.input.gas?.amount ?? bridge.gas?.amount ?? 0);
          // TOKEN
          const currentTokenBalanceIntoMilkomeda = +(tokenBalance?.balance.toFixed() ?? 0);
          const waitingTokenAmount = +(bridge.input.token?.amount ?? 0);
          if (
            currentGasBalanceIntoMilkomeda >= waitingGasAmount &&
            currentTokenBalanceIntoMilkomeda >= waitingTokenAmount
          ) {
            stopCheckBalance();
            resolve(bridge);
          }
        },
      );

      abortControllerCheckBalance.signal.addEventListener('abort', () => {
        stopCheckBalance();
        reject();
      });
    });
  }

  async function processingDoBridge(
    bridge: Bridge,
    cardanoWallet: CardanoWallet,
    milkomedaWalletAddress: string,
  ) {
    const bridgeResponse = await bridgeFromCardanoToMilkomeda(
      bridge.gas,
      bridge.token,
      cardanoWallet,
      milkomedaWalletAddress,
    );
    console.log('bridge response : ', bridgeResponse);

    return bridgeResponse;
  }

  async function processingTransactionFrom(bridgeResponse: BridgeResponse) {
    const cardanoNetworkId = walletState.wallets[APP_NETWORK_NAME].chainId;
    const cryptoSDKConnector: CryptoSDKConnector =
      getInstance().provider.value['cryptoSdkConnector'];

    const fromTx = bridgeResponse.from.tx;
    if (!fromTx) {
      console.warn(`[MILKOMEDA_WSC:BRIDGE:BRIDGE_FROM_CARDANO] 'from' transaction is undefined.`);
      throw Error('Can not get `from` transaction hash when bridge.');
    }
    console.log(
      `waiting block for 'from' `,
      '( txHash : ',
      fromTx.hash,
      ' networkId : ',
      cardanoNetworkId,
      ' )',
    );
    const fromBlock = await cryptoSDKConnector.blockfrost!.getTxBlockHash(
      fromTx.hash,
      cardanoNetworkId,
    );
    console.log(`has block for 'from' [${fromTx.hash}] : `, fromBlock);

    return bridgeResponse;
  }

  async function processingTransactionTo(bridgeResponse: BridgeResponse) {
    console.log(`waiting 'to' transaction`);
    const toTx = await bridgeResponse.to.tx;
    console.log(`has 'to' transaction : `, toTx);
    if (!toTx) {
      console.warn(`[MILKOMEDA_WSC:BRIDGE:BRIDGE_FROM_CARDANO] 'to' transaction is undefined.`);
      throw Error('Can not get `to` transaction hash when bridge.');
    }
    console.log(`waiting block for 'to' `, '( txHash : ', toTx.hash, ' )');
    const toBlock = await getInstance().web3.getSigner().provider.getTransaction(toTx.hash);
    console.log(`has block for 'to' [toTx.hash] : `, toBlock);

    return bridgeResponse;
  }

  async function processingWaitingTransactionFrom(
    bridge: Bridge,
    cardanoWallet: CardanoWallet,
    milkomedaWalletAddress: string,
  ): Promise<BridgeResponse> {
    // BRIDGE
    const bridgeResponse = await processingDoBridge(bridge, cardanoWallet, milkomedaWalletAddress);

    // PROCESSING TX FROM
    await processingTransactionFrom(bridgeResponse);

    return bridgeResponse;
  }

  async function* generatorWaitingTransactionFrom(
    bridge: Bridge[],
    cardanoWallet: CardanoWallet,
    milkomedaWalletAddress: string,
  ) {
    for (let index = 0; index < bridge.length; index++) {
      const response = await processingWaitingTransactionFrom(
        bridge[index],
        cardanoWallet,
        milkomedaWalletAddress,
      );

      yield { response, bridge: bridge[index] };
    }

    yield null;
  }

  async function waitingBalanceIntoMilkomedaAndRunTransactionTo(
    currentBridgeResponse: {
      response: BridgeResponse;
      bridge: Bridge;
    },
    abortControllerCheckBalance: AbortController,
  ): Promise<BridgeResponse> {
    return new Promise<BridgeResponse>((resolve, reject) => {
      processingTransactionTo(currentBridgeResponse.response).catch(reject);

      processingCheckBalanceIntoMilkomeda(currentBridgeResponse.bridge, abortControllerCheckBalance)
        .then(() => resolve(currentBridgeResponse.response))
        .catch(reject);
    });
  }

  async function executeBridges(
    waitingTransactionFrom: AsyncGenerator<
      {
        response: BridgeResponse;
        bridge: Bridge;
      } | null,
      void,
      unknown
    >,
    abortControllerCheckBalance: AbortController,
  ) {
    let hasNextBridge = true;
    const bridges: Promise<BridgeResponse>[] = [];

    while (hasNextBridge) {
      const next = await waitingTransactionFrom.next();
      hasNextBridge = !next.done;
      const currentBridgeResponse = next.value;

      if (hasNextBridge && currentBridgeResponse) {
        bridges.push(
          waitingBalanceIntoMilkomedaAndRunTransactionTo(
            currentBridgeResponse,
            abortControllerCheckBalance,
          ),
        );
      }
    }

    return bridges;
  }

  function prepareTokensForBridge(cMilkomedaWSCBridgeState: IMilkomedaWSCBridgeState) {
    const bridge: Bridge[] = [];

    if (!cMilkomedaWSCBridgeState.bridge.length && cMilkomedaWSCBridgeState.gas) {
      bridge.push({
        gas: cMilkomedaWSCBridgeState.gas,
        token: undefined,
        input: {
          gas: cMilkomedaWSCBridgeState.input.ADA ?? undefined,
          token: undefined,
        },
      });
    }

    for (let index = 0; index < cMilkomedaWSCBridgeState.bridge.length; index++) {
      const bridgeToken = cMilkomedaWSCBridgeState.bridge[index];
      const inputToken = cMilkomedaWSCBridgeState.input.tokens.find(({ token }) =>
        token.equalsBySymbol(bridgeToken.token),
      );

      if (index === 0) {
        bridge.push({
          gas: cMilkomedaWSCBridgeState.gas ? cMilkomedaWSCBridgeState.gas : undefined,
          token: bridgeToken,
          input: {
            gas: cMilkomedaWSCBridgeState.input.ADA ?? undefined,
            token: inputToken,
          },
        });
      } else {
        bridge.push({
          gas: undefined,
          token: bridgeToken,
          input: {
            gas: undefined,
            token: inputToken,
          },
        });
      }
    }

    return bridge;
  }

  async function doBridgeFromCardano() {
    console.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] doBridgeFromCardano');
    const cMilkomedaWSCBridgeState: IMilkomedaWSCBridgeState = _.cloneDeep(milkomedaWSCBridgeState);
    const cardanoWallet: CardanoWallet = getProviderBy(APP_NETWORK_NAME);
    const milkomedaWalletAddress = walletState.wallets[SELECTED_NETWORK_NAME].account;
    const cryptoSDKConnector: CryptoSDKConnector =
      getInstance().provider.value['cryptoSdkConnector'];

    if (!cryptoSDKConnector) {
      console.error('[MILKOMEDA_WSC:BRIDGE:BRIDGE_FROM_CARDANO] Can not get flint connector.');
      throw Error('Can not get flint connector for bridge.');
    }

    const notificationId = 'bridge_token_from_cardano';

    console.log('state for bridge : ', cMilkomedaWSCBridgeState);

    const abortControllerCheckBalance = new AbortController();
    try {
      addNotification(
        getBridgeFromCardanoNotificationOptions({
          id: notificationId,
          status: 'inProgress',
        }),
      );

      const bridgeTokens = prepareTokensForBridge(cMilkomedaWSCBridgeState);

      const waitingTransactionFromGenerator = generatorWaitingTransactionFrom(
        bridgeTokens,
        cardanoWallet,
        milkomedaWalletAddress,
      );
      const bridgeResponses = await Promise.all(
        await executeBridges(waitingTransactionFromGenerator, abortControllerCheckBalance),
      );
      console.log('bridge responses : ', bridgeResponses);
      abortControllerCheckBalance.abort();

      let explorerLink: string | undefined;
      // NOTE: Check that wrap one token
      if (bridgeResponses.length === 1) {
        explorerLink = await getExplorerLink(bridgeResponses[0]);
      }

      addNotification(
        getBridgeFromCardanoNotificationOptions({
          id: notificationId,
          status: 'success',
          explorerLink,
        }),
      );
    } catch (error) {
      console.error('[MILKOMEDA_WSC:BRIDGE:BRIDGE_FROM_CARDANO] ERROR : ', error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }
      addNotification(
        getBridgeFromCardanoNotificationOptions({
          id: notificationId,
          status: 'error',
        }),
      );
      abortControllerCheckBalance.abort();
      throw error;
    } finally {
      console.groupEnd();
    }
  }
  // ===

  return {
    milkomedaWSCBridgeState,
    setDestinationAddress,
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    setIsUnwrapADAFromMilkomeda,
    doBridgeFromCardano,
    $reset,
    calculateMaxADAIntoCardanoAndMilkomeda,
    estimateMaxBridgeAmountFromCardano,
  };
}

async function getExplorerLink(
  bridgeResponse: BridgeResponse | undefined,
): Promise<string | undefined> {
  let explorerLink: string | undefined;

  if (bridgeResponse) {
    const hashFrom = bridgeResponse.from.tx?.hash;
    const hashTo = (await bridgeResponse.to.tx)?.hash;
    if (hashTo) {
      explorerLink = getScanLink(hashTo, 'transaction', +DEFAULT_NETWORK_ID!);
    } else if (hashFrom) {
      explorerLink = getScanLink(hashFrom, 'transaction', +DEFAULT_CARDANO_CHAIN_ID);
    }
  }

  return explorerLink;
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].WSC;
}

function loggingProcessingCheckBalanceIntoMilkomeda(
  bridge: Bridge,
  gasBalance: TokenBalance | null,
  tokenBalance: TokenBalance | null,
) {
  if (isLoggingDisabled()) return;

  console.groupCollapsed('[MILKOMEDA_WSC:BRIDGE] Check balance into Milkomeda');
  const gas = bridge.gas?.token;
  const token = bridge.token?.token;
  if (gas) {
    const currentGasBalanceIntoMilkomeda = +(gasBalance?.balance.toFixed() ?? 0);
    const waitingGasAmount = +(bridge.input.gas?.amount ?? bridge.gas?.amount ?? 0);
    console.log(`Balance [ ${gas.symbol} ] in Milkomeda : `, currentGasBalanceIntoMilkomeda);
    console.log(`Amount [ ${gas.symbol} ] : `, waitingGasAmount);
    console.log(`Enough [ ${gas.symbol} ] : `, currentGasBalanceIntoMilkomeda >= waitingGasAmount);
  }
  if (token) {
    const currentTokenBalanceIntoMilkomeda = +(tokenBalance?.balance.toFixed() ?? 0);
    const waitingTokenAmount = +(bridge.input.token?.amount ?? 0);
    console.log(`Balance [ ${token.symbol} ] in Milkomeda : `, currentTokenBalanceIntoMilkomeda);
    console.log(`Amount [ ${token.symbol} ] : `, waitingTokenAmount);
    console.log(
      `Enough [ ${token.symbol} ] : `,
      currentTokenBalanceIntoMilkomeda >= waitingTokenAmount,
    );
  }
  console.groupEnd();
}
