import { computed, reactive, watch } from 'vue';
import { defineStore } from 'pinia';
import { fromWei } from '@/sdk/utils';
import { calculateGasForReversBridge } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { Staking } from '@/sdk/entities/staking';
import { Token } from '@/sdk/entities/token';
import { StakingPool } from '@/store/modules/staking/models/staking-pool';
import { IStakingUnstakeForm, UnstakeOptions } from '../models/staking-unstake-form.interface';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useStakingMilkomedaWSCBridge } from '@/store/modules/staking/useStakingMilkomedaWSCBridge';
import { useStakingMilkomedaWSCUnwrapBridge } from '@/store/modules/staking/useStakingMilkomedaWSCUnwrapBridge';
import { useAutoStakingUnstake } from '@/store/modules/staking/auto/unstake/useAutoStakingUnstake';
import { useManualStakingUnstake } from '@/store/modules/staking/manual/unstake/useManualStakingUnstake';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';

type IStakingUnstake = {
  stakingPool: StakingPool | null;
  stakingUnstakeForm: IStakingUnstakeForm | null;
};

export const useStakingUnstake = defineStore('stakingUnstake', () => {
  const { getTokenBySymbolAndChainId, isPresentTokenIntoNetwork } = useTokens();
  const {
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useStakingMilkomedaWSCBridge();
  const {
    setBridgeTokensFromMilkomeda,
    setIsEVMFromMilkomeda,
    $reset: resetMilkomedaWSCUnwrapBridge,
  } = useStakingMilkomedaWSCUnwrapBridge();
  const {
    stakingUnstakeForm: autoStakingUnstakeForm,
    setUnstakeFromFarm: autoSetUnstakeFromFarm,
    doUnstake: autoDoUnstake,
    $reset: autoReset,
  } = useAutoStakingUnstake();
  const {
    stakingUnstakeForm: manualStakingUnstakeForm,
    setUnstakeFromPool: manualSetUnstakeFromPool,
    doUnstake: manualDoUnstake,
    $reset: manualReset,
  } = useManualStakingUnstake();

  const stakingUnstake = reactive<IStakingUnstake>({
    stakingPool: null,
    stakingUnstakeForm: null,
  });

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  const setUnstakeFromPoolByStakingPool: Record<
    StakingPool,
    (pool: Staking, token: Token, amountInWei: string, options: UnstakeOptions) => void
  > = {
    auto: (pool: Staking, token: Token, amountInWei: string, options: UnstakeOptions) =>
      autoSetUnstakeFromFarm(pool, token, amountInWei, options),
    manual: (pool: Staking, token: Token, amountInWei: string) =>
      manualSetUnstakeFromPool(pool, token, amountInWei),
  };

  const doStakeByStakingPool: Record<StakingPool, () => void> = {
    auto: () => autoDoUnstake(),
    manual: () => manualDoUnstake(),
  };

  function $reset(): void {
    autoReset();
    manualReset();
    stakingUnstake.stakingPool = null;
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();
  }

  function checkUnstakeForBridgeFromMilkomeda(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();

    const bridgeTokens: BridgeToken[] = [];
    if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
      bridgeTokens.push({
        amount: fromWei(amountInWei, token.decimals).toString(),
        token: token,
      });
    }

    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(!!bridgeTokens.length);

    if (bridgeTokens.length) {
      setIsEVMFromMilkomeda(false);
      setBridgeTokensFromMilkomeda(bridgeTokens);
    } else {
      resetMilkomedaWSCUnwrapBridge();
    }

    const gas = calculateGasForReversBridge(cardanoADATokenForBridge.value, bridgeTokens);
    setBridgeTokensFromCardano(
      gas ? [gas] : [{ amount: '0', token: cardanoADATokenForBridge.value }],
    );
  }

  function setUnstakeFromPool(
    stakingPool: StakingPool,
    pool: Staking,
    token: Token,
    amountInWei: string,
    options: UnstakeOptions,
  ): void {
    stakingUnstake.stakingPool = stakingPool;
    setUnstakeFromPoolByStakingPool[stakingUnstake.stakingPool](pool, token, amountInWei, options);
  }

  async function doUnstake() {
    if (!stakingUnstake.stakingPool) return;

    await doStakeByStakingPool[stakingUnstake.stakingPool]();
  }

  watch(
    () => stakingUnstake.stakingPool,
    stakingPool => {
      if (!stakingPool) {
        stakingUnstake.stakingUnstakeForm = null;
        return;
      }

      if (stakingPool === 'auto') {
        stakingUnstake.stakingUnstakeForm = autoStakingUnstakeForm;
      } else {
        stakingUnstake.stakingUnstakeForm = manualStakingUnstakeForm;
      }
    },
  );

  return {
    stakingUnstake,
    checkUnstakeForBridgeFromMilkomeda,
    setUnstakeFromPool,
    doUnstake,
    $reset,
  };
});
