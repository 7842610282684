import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { STEP_STATUS, STEP_TYPE, StepInfo } from '@/components/stepper/stepper.types';
import { SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useSwap } from '@/store/modules/swap/useSwap';
import { useSwapMilkomedaWSCBridge } from '@/store/modules/swap/useSwapMilkomedaWSCBridge';
import { useSwapMilkomedaWSCUnwrapBridge } from '@/store/modules/swap/useSwapMilkomedaWSCUnwrapBridge';

interface ISwapStepsState {
  steps: StepInfo[];
  currentStep: StepInfo | null;
  isShown: boolean;
  inProgress: boolean;
}

export const useSwapSteps = defineStore('swapSteps', () => {
  const { t } = useI18n();
  const { walletState } = useWallet();
  const { swapForm, setAllowance, doSwap } = useSwap();
  const { milkomedaWSCBridgeState, doBridgeFromCardano } = useSwapMilkomedaWSCBridge();
  const { milkomedaWSCUnwrapBridgeState, doApproveForBridgeFromMilkomeda, doBridgeFromMilkomeda } =
    useSwapMilkomedaWSCUnwrapBridge();

  const walletName = computed(() => {
    return walletState.wallets[SELECTED_NETWORK_NAME].connector.name;
  });

  const doStepAction = {
    [STEP_TYPE.BRIDGE]: async () => {
      await doBridgeFromCardano();
    },
    [STEP_TYPE.APPROVE]: async () => {
      await setAllowance();
    },
    [STEP_TYPE.SIGN]: async () => {
      await doSwap();
    },
    [STEP_TYPE.REVERSE_APPROVE]: async () => {
      await doApproveForBridgeFromMilkomeda(true);
    },
    [STEP_TYPE.REVERSE_BRIDGE]: async () => {
      await doBridgeFromMilkomeda();
    },
  };

  let nextStep = {};

  const swapSteps = reactive<ISwapStepsState>({
    steps: [],
    currentStep: null,
    isShown: false,
    inProgress: false,
  });

  function $reset() {
    nextStep = {};
    swapSteps.steps = [];
    swapSteps.currentStep = null;
  }

  // BRIDGE
  function addBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCBridgeState.needBridge) {
      const step = {
        name: t('swap.steps.bridge'),
        type: STEP_TYPE.BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      swapSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      return {
        prevStep: STEP_TYPE.BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // APPROVE
  function addApproveStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (!swapForm.hasAllowance && !swapForm.isSwapSameTokensFromMilkomedaToCardano) {
      const step = {
        name: t('swap.approve', { token: swapForm.input.token?.symbol }),
        type: STEP_TYPE.APPROVE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      swapSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.APPROVE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // SIGN
  function addSignStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (!swapForm.isSwapSameTokensFromMilkomedaToCardano) {
      const step = {
        name: t('swap.steps.signSwap'),
        type: STEP_TYPE.SIGN,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      swapSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }

      return {
        prevStep: STEP_TYPE.SIGN,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // REVERSE APPROVE
  function addReverseApproveStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCUnwrapBridgeState.needRevertBridge && !swapForm.output.token?.isETHToken()) {
      const step = {
        name: t('swap.steps.reverseApprove', { token: swapForm.output.token?.symbol }),
        type: STEP_TYPE.REVERSE_APPROVE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      swapSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.REVERSE_APPROVE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // REVERSE BRIDGE
  function addReverseBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCUnwrapBridgeState.needRevertBridge) {
      const step = {
        name: t('swap.steps.reverseBridge', { wallet: walletName.value }),
        type: STEP_TYPE.REVERSE_BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      swapSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.REVERSE_BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  function prepareSteps(): void {
    let step: StepInfo | null = null;
    let prevStep: STEP_TYPE | null = null;

    let state = addBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addApproveStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addSignStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addReverseApproveStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addReverseBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    // next step
    if (prevStep) {
      nextStep[prevStep] = null;
    }

    swapSteps.currentStep = step;
  }

  async function doStep(step: StepInfo) {
    step.status = STEP_STATUS.PROGRESS;
    await doStepAction[step.type]();
    step.status = STEP_STATUS.SUCCESS;
    swapSteps.currentStep = nextStep[step.type];
  }

  async function runSteps() {
    if (!swapSteps.currentStep) return;
    swapSteps.inProgress = true;

    try {
      while (swapSteps.currentStep) {
        await doStep(swapSteps.currentStep);
      }
    } catch (err) {
      swapSteps.currentStep.status = STEP_STATUS.ERROR;
      throw Error(err);
    } finally {
      swapSteps.inProgress = false;
    }
  }

  return {
    swapSteps,
    prepareSteps,
    runSteps,
    $reset,
  };
});
