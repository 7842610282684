import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { STEP_STATUS, STEP_TYPE, StepInfo } from '@/components/stepper/stepper.types';
import { useEasyModeAddLiquidity } from '@/store/modules/portfolios/easy-mode/add-liquidity/useEasyModeAddLiquidity';
import { usePortfoliosMilkomedaWSCBridge } from '@/store/modules/portfolios/usePortfoliosMilkomedaWSCBridge';

interface IAddLiquidityStepsState {
  steps: StepInfo[];
  currentStep: StepInfo | null;
  isShown: boolean;
  inProgress: boolean;
}

export const useEasyModeAddLiquiditySteps = defineStore('easyModeAddLiquiditySteps', () => {
  const { t } = useI18n();
  const easyModeAddLiquidityStore = useEasyModeAddLiquidity();
  const { setAllowance, doAddLiquidity } = useEasyModeAddLiquidity();
  const { milkomedaWSCBridgeState, doBridgeFromCardano } = usePortfoliosMilkomedaWSCBridge();

  const doStepAction = {
    [STEP_TYPE.BRIDGE]: async () => {
      await doBridgeFromCardano();
    },
    [STEP_TYPE.APPROVE]: async () => {
      await setAllowance();
    },
    [STEP_TYPE.SIGN]: async () => {
      await doAddLiquidity();
    },
  };

  let nextStep = {};

  const addLiquiditySteps = reactive<IAddLiquidityStepsState>({
    steps: [],
    currentStep: null,
    isShown: false,
    inProgress: false,
  });

  function $reset() {
    nextStep = {};
    addLiquiditySteps.steps = [];
    addLiquiditySteps.currentStep = null;
    addLiquiditySteps.isShown = false;
    addLiquiditySteps.inProgress = false;
  }

  // BRIDGE
  function addBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCBridgeState.needBridge) {
      const step = {
        name: t('portfolio.steps.bridge'),
        type: STEP_TYPE.BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      addLiquiditySteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      return {
        prevStep: STEP_TYPE.BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // APPROVE
  function addApproveStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (easyModeAddLiquidityStore.needApproveTokens) {
      const step = {
        name: t('portfolio.steps.approve'),
        type: STEP_TYPE.APPROVE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      addLiquiditySteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.APPROVE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // SIGN
  function addSignStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    const step = {
      name: t('portfolio.steps.sign.deposit'),
      type: STEP_TYPE.SIGN,
      status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
    };
    addLiquiditySteps.steps.push(step);
    // set current step
    if (!currentStep) {
      currentStep = step;
    }
    // next step
    if (prevStep) {
      nextStep[prevStep] = step;
    }

    return {
      prevStep: STEP_TYPE.SIGN,
      currentStep,
    };
  }

  function prepareSteps(): void {
    let step: StepInfo | null = null;
    let prevStep: STEP_TYPE | null = null;

    let state = addBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addApproveStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addSignStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    // next step
    if (prevStep) {
      nextStep[prevStep] = null;
    }

    addLiquiditySteps.currentStep = step;
  }

  async function doStep(step: StepInfo) {
    step.status = STEP_STATUS.PROGRESS;
    await doStepAction[step.type]();
    step.status = STEP_STATUS.SUCCESS;
    addLiquiditySteps.currentStep = nextStep[step.type];
  }

  async function runSteps() {
    if (!addLiquiditySteps.currentStep) return;
    addLiquiditySteps.inProgress = true;

    try {
      while (addLiquiditySteps.currentStep) {
        await doStep(addLiquiditySteps.currentStep);
      }
    } catch (err) {
      addLiquiditySteps.currentStep.status = STEP_STATUS.ERROR;
      throw Error(err);
    } finally {
      addLiquiditySteps.inProgress = false;
    }
  }

  return {
    addLiquiditySteps,
    prepareSteps,
    runSteps,
    $reset,
  };
});
