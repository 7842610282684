import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { STEP_STATUS, STEP_TYPE, StepInfo } from '@/components/stepper/stepper.types';
import { useFarmingMilkomedaWSCBridge } from '@/store/modules/farming/useFarmingMilkomedaWSCBridge';
import { useFarmingStake } from './useFarmingStake';

interface IFarmingStakeStepsState {
  steps: StepInfo[];
  currentStep: StepInfo | null;
  isShown: boolean;
}

export const useFarmingStakeSteps = defineStore('farmingStakeSteps', () => {
  const { t } = useI18n();
  const { milkomedaWSCBridgeState, doBridgeFromCardano } = useFarmingMilkomedaWSCBridge();
  const { farmingStakeForm, setAllowance, doStake } = useFarmingStake();

  let nextStep = {};

  const doStepAction = {
    [STEP_TYPE.BRIDGE]: async () => {
      await doBridgeFromCardano();
    },
    [STEP_TYPE.APPROVE]: async () => {
      await setAllowance();
    },
    [STEP_TYPE.SIGN]: async () => {
      await doStake();
    },
  };

  const farmingStakeSteps = reactive<IFarmingStakeStepsState>({
    steps: [],
    currentStep: null,
    isShown: false,
  });

  function $reset() {
    nextStep = {};
    farmingStakeSteps.steps = [];
    farmingStakeSteps.currentStep = null;
    farmingStakeSteps.isShown = false;
  }

  // BRIDGE
  function addBridgeStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (milkomedaWSCBridgeState.needBridge) {
      const step = {
        name: t('farming.steps.bridge'),
        type: STEP_TYPE.BRIDGE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      farmingStakeSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      return {
        prevStep: STEP_TYPE.BRIDGE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // APPROVE
  function addApproveStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    if (!farmingStakeForm.hasAllowance) {
      const step = {
        name: t('farming.steps.approve'),
        type: STEP_TYPE.APPROVE,
        status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
      };
      farmingStakeSteps.steps.push(step);
      // set current step
      if (!currentStep) {
        currentStep = step;
      }
      // next step
      if (prevStep) {
        nextStep[prevStep] = step;
      }
      return {
        prevStep: STEP_TYPE.APPROVE,
        currentStep,
      };
    }

    return {
      prevStep,
      currentStep,
    };
  }

  // SIGN
  function addSignStep(
    currentStep: StepInfo | null,
    prevStep: STEP_TYPE | null,
  ): {
    prevStep: STEP_TYPE | null;
    currentStep: StepInfo | null;
  } {
    const step = {
      name: t('farming.steps.sign.stake'),
      type: STEP_TYPE.SIGN,
      status: currentStep ? STEP_STATUS.NULL : STEP_STATUS.ACTIVE,
    };
    farmingStakeSteps.steps.push(step);
    // set current step
    if (!currentStep) {
      currentStep = step;
    }
    // next step
    if (prevStep) {
      nextStep[prevStep] = step;
    }

    return {
      prevStep: STEP_TYPE.SIGN,
      currentStep,
    };
  }

  function prepareSteps(): void {
    let step: StepInfo | null = null;
    let prevStep: STEP_TYPE | null = null;

    let state = addBridgeStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addApproveStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    state = addSignStep(step, prevStep);
    step = state.currentStep;
    prevStep = state.prevStep;

    // next step
    if (prevStep) {
      nextStep[prevStep] = null;
    }

    farmingStakeSteps.currentStep = step;
  }

  async function doStep(step: StepInfo) {
    step.status = STEP_STATUS.PROGRESS;
    await doStepAction[step.type]();
    step.status = STEP_STATUS.SUCCESS;
    farmingStakeSteps.currentStep = nextStep[step.type];
  }

  async function runSteps() {
    if (!farmingStakeSteps.currentStep) return;

    try {
      while (farmingStakeSteps.currentStep) {
        await doStep(farmingStakeSteps.currentStep);
      }
    } catch (error) {
      farmingStakeSteps.currentStep.status = STEP_STATUS.ERROR;
      throw Error(error);
    }
  }

  return {
    farmingStakeSteps,
    prepareSteps,
    runSteps,
    $reset,
  };
});
