import invariant from 'tiny-invariant';
import BigNumber from 'bignumber.js';
import { BIG_ZERO, max, min } from '@/utils/bigNumber';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { Token } from '@/sdk/entities/token';
import { fromWei, toFixedWei, toWei } from '@/sdk/utils';
import { useTokens } from '@/store/modules/tokens/useTokens';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

const {
  getTokenBySymbolAndChainId,
  isPresentTokenIntoNetwork,
  isPresentTokenIntoNetworkByAddress,
} = useTokens();

export const BRIDGE_ADA_FOR_MILKOMEDA_WHEN_CARDANO = 5;
export const BRIDGE_ADA_FOR_MILKOMEDA_WHEN_EVM = 2;

export const BRIDGE_ADA_BY_SDK_FOR_EXTRA_TOKEN = 0.9;

export const MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA = 1;
export const MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA = 3;

export const SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA = 1;

export const TX_FEE_CARDANO_IN_ADA: Readonly<BigNumber> = BigNumber(0.2);
export const TX_FEE_MILKOMEDA_IN_ADA: Readonly<BigNumber> = BigNumber(0.04);

export const BRIDGE_FEE_CARDANO_IN_ADA: Readonly<BigNumber> = BigNumber(0.1);
export const BRIDGE_FEE_MILKOMEDA_IN_ADA: Readonly<BigNumber> = BigNumber(1);
export const KEEP_AMOUNT_INTO_CARDANO_IN_ADA: Readonly<BigNumber> = BigNumber(2.4);

// NOTE:
// Looks like tx_fee is more 0.2
export const TX_FEE_CARDANO_WITH_MAGIC_NUMBER_IN_ADA: Readonly<BigNumber> =
  TX_FEE_CARDANO_IN_ADA.plus(0.1);

export function calculateMinADABalanceToCardano(
  txFeeCardanoInADA: BigNumber,
  fromAmountInADA: BigNumber = BIG_ZERO,
  isEVM = false,
  isUnwrapADA = false,
) {
  invariant(
    txFeeCardanoInADA.gt(BIG_ZERO),
    `Cardano's tx fee is not more zero (${txFeeCardanoInADA.toString()}).`,
  );

  const forGasCardano = isEVM
    ? BRIDGE_ADA_FOR_MILKOMEDA_WHEN_EVM
    : BRIDGE_ADA_FOR_MILKOMEDA_WHEN_CARDANO;

  const needDecreaseOnEvmLockUp = isUnwrapADA ? SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA : BIG_ZERO;

  const minBalanceIntoCardanoInADA = BigNumber(forGasCardano)
    .plus(KEEP_AMOUNT_INTO_CARDANO_IN_ADA)
    .plus(BRIDGE_FEE_CARDANO_IN_ADA)
    .plus(txFeeCardanoInADA)
    .plus(fromAmountInADA)
    .minus(needDecreaseOnEvmLockUp);

  console.log(
    '[MILKOMEDA_WSC:HELPER] Calc min balance ADA into Cardano [ ADA ] : ',
    minBalanceIntoCardanoInADA.toString(),
  );

  return minBalanceIntoCardanoInADA;
}

export const ADA_FOR_MILKOMEDA_WHEN_CARDANO = 5;
export const ADA_FOR_MILKOMEDA_WHEN_EVM = 0.1;

export function calculateGasForReversBridge(
  ADA: Token,
  bridgeTokens: BridgeToken[],
): BridgeToken | null {
  if (!bridgeTokens.length) return null;

  // NOTE
  // When we bridge ADA, we will add `BRIDGE_ADA_FOR_MILKOMEDA_WHEN_CARDANO`
  // see: useMilkomedaWSCBridgeCalculations -> calculateAmountsForADAAndCardano
  let amount = BIG_ZERO;
  for (let index = 0; index < bridgeTokens.length - 1; index++) {
    amount = amount.plus(BRIDGE_ADA_FOR_MILKOMEDA_WHEN_CARDANO);
  }

  return {
    token: ADA,
    amount: amount.toString(),
  };
}

export function minDecimalsFromCardanoAndMilkomeda(milkomedaToken: Token) {
  let minDecimals = milkomedaToken.decimals;

  if (
    ENABLE_FAKE_CARDANO_NETWORK &&
    isPresentTokenIntoNetwork(milkomedaToken.symbol!, +DEFAULT_CARDANO_CHAIN_ID) &&
    isPresentTokenIntoNetworkByAddress(milkomedaToken.address, +DEFAULT_NETWORK_ID!)
  ) {
    const cardanoToken = getTokenBySymbolAndChainId(
      milkomedaToken.symbol!,
      +DEFAULT_CARDANO_CHAIN_ID,
    );
    minDecimals = Math.min(milkomedaToken.decimals, cardanoToken.decimals);
  }

  return minDecimals;
}

export function normalizeAmountByMinDecimals(amountInToken: string, milkomedaToken: Token) {
  const amountInWei = toWei(amountInToken, milkomedaToken.decimals);
  const amountByMinDecimalsInWei = normalizeWeiByMinDecimalsWhenExistCardanoToken(
    amountInWei,
    milkomedaToken,
  );

  return fromWei(amountByMinDecimalsInWei, milkomedaToken.decimals);
}

export function normalizeWeiByMinDecimalsWhenExistCardanoToken(
  amountInWei: BigNumber,
  token: Token,
) {
  const amount = amountInWei.shiftedBy(-token.decimals);

  const minDecimals = minDecimalsFromCardanoAndMilkomeda(token);

  const amountByMinDecimalsInWei = toFixedWei(amount.shiftedBy(minDecimals));
  const amountByMinDecimalsInToken = amountByMinDecimalsInWei.shiftedBy(-minDecimals);

  return amountByMinDecimalsInToken.shiftedBy(token.decimals);
}

export function calculateMaxAmountADAIntoCardanoAndMilkomeda(
  balanceADAIntoCardano: BigNumber,
  estimatedMaxADABridgeFromCardano: BigNumber | undefined,
  balanceADAIntoMilkomeda: BigNumber,
  totalFeesAndLookUps: BigNumber,
) {
  let amountADAValidForBridgeFromCardano = balanceADAIntoCardano;
  if (estimatedMaxADABridgeFromCardano) {
    amountADAValidForBridgeFromCardano = min(
      estimatedMaxADABridgeFromCardano,
      balanceADAIntoCardano,
    );
  }

  const isBalanceIntoCardanoMoreThanKeep = amountADAValidForBridgeFromCardano.gt(
    KEEP_AMOUNT_INTO_CARDANO_IN_ADA,
  );

  LOGGER.groupCollapsed(
    `[MILKOMEDA_WSC:MAX:ADA] Calc max ADA | Case: amount from Cardano ${
      isBalanceIntoCardanoMoreThanKeep ? '>' : '<'
    } ${KEEP_AMOUNT_INTO_CARDANO_IN_ADA} `,
  );

  if (isBalanceIntoCardanoMoreThanKeep) {
    const maxAmount = max(
      amountADAValidForBridgeFromCardano
        .minus(KEEP_AMOUNT_INTO_CARDANO_IN_ADA)
        .plus(balanceADAIntoMilkomeda)
        .minus(TX_FEE_CARDANO_IN_ADA)
        .minus(totalFeesAndLookUps),
      BIG_ZERO,
    );

    LOGGER.log('balance into Cardano : ', balanceADAIntoCardano.toString());
    LOGGER.log(
      'estimated max amount from Cardano : ',
      estimatedMaxADABridgeFromCardano?.toString(),
    );
    LOGGER.log(
      'valid bridge amount from Cardano : ',
      amountADAValidForBridgeFromCardano.toString(),
    );
    LOGGER.log('balance into Milkomeda : ', balanceADAIntoMilkomeda.toString());
    LOGGER.log('KEEP value : ', KEEP_AMOUNT_INTO_CARDANO_IN_ADA.toString());
    LOGGER.log('tx Cardano fee : ', TX_FEE_CARDANO_IN_ADA.toString());
    LOGGER.log('total bridge fees and look ups : ', totalFeesAndLookUps.toString());
    LOGGER.log('MAX [ ADA ] : ', maxAmount.toString());
    LOGGER.groupEnd();

    return maxAmount;
  }

  const maxAmount = max(balanceADAIntoMilkomeda.minus(totalFeesAndLookUps), BIG_ZERO);

  LOGGER.log('balance into Milkomeda : ', balanceADAIntoMilkomeda.toString());
  LOGGER.log('total bridge fees and look ups : ', totalFeesAndLookUps.toString());
  LOGGER.log('MAX [ ADA ] : ', maxAmount.toString());
  LOGGER.groupEnd();

  return maxAmount;
}

export function calculateMaxAmountADAWhenUnwrapFromMilkomeda(balanceADAIntoMilkomeda: BigNumber) {
  LOGGER.groupCollapsed(`[MILKOMEDA_WSC:MAX:ADA] Calc max ADA | Case: unwrap ADA `);

  const maxAmount = max(
    balanceADAIntoMilkomeda
      .minus(BRIDGE_FEE_MILKOMEDA_IN_ADA)
      .minus(SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA),
    BIG_ZERO,
  );

  LOGGER.log('balance into Milkomeda : ', balanceADAIntoMilkomeda.toString());
  LOGGER.log('bridge fee : ', BRIDGE_FEE_MILKOMEDA_IN_ADA.toString());
  LOGGER.log('lock for Milkomeda gas : ', SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA.toString());
  LOGGER.log('MAX [ ADA ] : ', maxAmount.toString());
  LOGGER.groupEnd();

  return maxAmount;
}

export function calculateMinADAAmountIntoCardanoWhenOnlyBridgeFromCardano(
  countHowManyCanBridgeFromCardano: number,
) {
  const amountADAWhichWillBridgeBySDK = max(
    BigNumber((countHowManyCanBridgeFromCardano - 1) * BRIDGE_ADA_BY_SDK_FOR_EXTRA_TOKEN),
    0,
  );

  const txFeeCardanoForTokens = TX_FEE_CARDANO_IN_ADA.multipliedBy(
    countHowManyCanBridgeFromCardano ? countHowManyCanBridgeFromCardano : 1,
  );

  return KEEP_AMOUNT_INTO_CARDANO_IN_ADA.plus(BRIDGE_FEE_CARDANO_IN_ADA)
    .plus(txFeeCardanoForTokens)
    .plus(amountADAWhichWillBridgeBySDK)
    .plus(MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA)
    .plus(SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA);
}

export function howManyTokensCanBridgeFromCardano(tokens: Token[]) {
  if (!ENABLE_FAKE_CARDANO_NETWORK) return 0;

  let tokenCount = 0;
  let hasADA = false;

  tokens.forEach((token: Token) => {
    if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
      if (token.unwrapWETH().isETHToken()) {
        hasADA = true;
      }
      tokenCount++;
    }
  });

  // NOTE:
  // We use '-1', because ADA and token will be bridge from Cardano together
  return tokenCount === 1 ? tokenCount : hasADA ? tokenCount - 1 : tokenCount;
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].WSC;
}
