<template>
  <div v-if="sourceType === 'PAIR'">
    <TokenIcon
      :icon-src="tokens.token0.tokenIconUrl"
      :symbol="tokens.token0.symbol"
      full
      size="20"
    />
    <TokenIcon
      :icon-src="tokens.token1.tokenIconUrl"
      :symbol="tokens.token1.symbol"
      full
      size="20"
      class="ml-s3"
    />
  </div>
  <template v-else>
    <TokenGroupIcon :tokens="tokens" />
  </template>
</template>

<script>
import TokenIcon from '@/components/token/TokenIcon';
import TokenGroupIcon from '@/components/token/TokenGroupIcon';
export default {
  name: 'FarmTitle',
  components: {
    TokenGroupIcon,
    TokenIcon,
  },
  props: {
    sourceType: String,
    tokens: [Object, Array],
  },
};
</script>

<style scoped></style>
